import { useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import HomeLayout from "~/layouts/home/HomeLayout";
import useBaseStore from "~/libs/store/base";
import { GradientTypography } from "~/components/common/IPTypo";
import { LottiePlayer } from "~/components/common/LottieWeb";
import { styled } from "@mui/material/styles";

interface IState {
  dataList: string[];
}

const Page = () => {
  const isMobile = useBaseStore((state) => state.isMobile);
  const [state, setState] = useState<IState>({
    dataList: [
      "검증된 전문가를 찾고 있어요.",
      "수업량 유연화 주간에 할 교육이 필요해요.",
      "학생들에게 탐구 활동의 기초를 지도하고 싶어요.",
      "전교생을 대상으로 수업을 진행하고 싶어요.",
      "개인별 진로에 맞춘 탐구 활동 및 피드백이 필요해요.",
    ],
  });

  return (
    <HomeLayout bgColor={"grey"}>
      <StyledWrap container>
        <Grid item xs={12} sm={6}>
          <StyledTitleWrap>
            <StyledMainText>
              <LottiePlayer
                file="page/science-lover"
                style={{
                  width: isMobile ? "80%" : 300,
                  height: isMobile ? 250 : 235,
                }}
              />
              <Typography
                component="h3"
                variant="h3"
                color="secondary"
                style={{ marginTop: isMobile ? 15 : 15 }}
              >
                이런 고민이 든다면
                <br />
                <GradientTypography title={"임팩트 클래스"} />
                를<br />
                시작해보세요.
              </Typography>
            </StyledMainText>
          </StyledTitleWrap>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledTextContent>
            {state.dataList.map((title) => {
              return (
                <Typography component="h5" variant="h5" color="secondary">
                  <Typography component="span" variant="h5" color="error">
                    ✓
                  </Typography>
                  {` ${title}`}
                </Typography>
              );
            })}
          </StyledTextContent>
        </Grid>
      </StyledWrap>
    </HomeLayout>
  );
};

const StyledTitleWrap = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  textAlign: "left",
  marginBottom: 50,
  "& h3": { fontWeight: 600 },
  "& img": { width: 100, height: "auto" },
  [theme.breakpoints.down("lg")]: {
    "& h3": { fontSize: theme.typography.h3.fontSize },
    "& h4": { fontSize: theme.typography.h4.fontSize },
  },
  [theme.breakpoints.down("md")]: {
    marginBottom: 0,
    textAlign: "center",
    "& h3": { fontSize: theme.typography.h5.fontSize },
    "& h4": { fontSize: theme.typography.subtitle1.fontSize },
  },
}));

const StyledWrap = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: { paddingBottom: 20 },
  [theme.breakpoints.down("xl")]: {
    paddingBottom: theme.spacing(5),
  },
}));

const StyledMainText = styled(Grid)(({ theme }) => ({
  width: "100%",
  position: "absolute",
  left: "55%",
  transform: "translateX(-55%)",
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    position: "relative",
    transform: "none",
    left: 0,
  },
}));

const StyledTextContent = styled(Grid)(({ theme }) => ({
  position: "relative",
  textAlign: "left",
  "& h5": {
    fontWeight: 600,
    background: theme.palette.primary.main,
    padding: 15,
    width: "75%",
    display: "inline-block",
    borderRadius: 15,
    marginTop: 20,
  },
  [theme.breakpoints.down("lg")]: {
    textAlign: "center",
    "& h5": {
      marginTop: 10,
      textAlign: "center",
      width: "85%",
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 15,
      paddingRight: 15,
      fontSize: theme.typography.subtitle1.fontSize,
    },
  },
  [theme.breakpoints.down("md")]: {
    "& h5": { width: "100%", fontSize: theme.typography.subtitle2.fontSize },
  },
  [theme.breakpoints.up("xl")]: { "& h5": { width: "65%" } },
}));

export default Page;
