import { ReactNode, Component } from "react";
import ChunkError from "./ChunkError";
import CrashError from "./CrashError";
import { captureException } from "@sentry/react";

interface IProps {
  children: ReactNode;
}

class ErrorBoundary extends Component<IProps> {
  state = {
    hasError: false,
    chunkError: false,
  };
  static getDerivedStateFromError(error: Error) {
    if (
      error.name?.includes("ChunkLoadError") ||
      error.name?.includes("NotFoundError") ||
      error.message?.includes("Failed to fetch dynamically imported module") ||
      error.message?.includes(`MIME type`)
    ) {
      return {
        chunkError: true,
      };
    }
    return { hasError: true };
  }
  componentDidCatch(error: Error, errorInfo: any) {
    if (import.meta.env.PROD) {
      captureException(error);
    }
  }
  handleResolveError = () => {
    this.setState({ hasError: false });
  };
  render() {
    if (this.state.chunkError) {
      return <ChunkError />;
    }
    if (this.state.hasError) {
      return <CrashError />;
    }
    return (
      <ErrorBoundaryWrapper hasError={this.state.hasError}>
        {this.props.children}
      </ErrorBoundaryWrapper>
    );
  }
}

type ErrorBoundaryWrapperProps = {
  children: React.ReactNode;
  hasError: boolean;
};

function ErrorBoundaryWrapper(props: ErrorBoundaryWrapperProps) {
  return <>{props.children}</>;
}

export default ErrorBoundary;
