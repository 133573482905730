import { useMemo, useReducer } from "react";

const defaultState = (defaultValue: any) => {
  return {
    loading: defaultValue === undefined || defaultValue === null,
    value: defaultValue,
  };
};

const reducer = () => (state: any, action: any) => {
  switch (action.type) {
    case "error":
      return {
        ...state,
        error: action.error,
        loading: false,
        value: undefined,
      };
    case "reset":
      return defaultState(action.defaultValue);
    case "value":
      return {
        ...state,
        error: undefined,
        loading: false,
        value: action.value,
      };
    default:
      return state;
  }
};

const useLoadingValue = (getDefaultValue: any) => {
  const defaultValue = getDefaultValue ? getDefaultValue() : undefined;
  const [state, dispatch] = useReducer(reducer(), defaultState(defaultValue));

  const reset = () => {
    const defaultValue = getDefaultValue ? getDefaultValue() : undefined;
    dispatch({ type: "reset", defaultValue });
  };

  const setError = (error: any) => {
    dispatch({ type: "error", error });
  };

  const setValue = (value: any) => {
    dispatch({ type: "value", value });
  };

  return useMemo(
    () => ({
      error: state.error,
      loading: state.loading,
      reset,
      setError,
      setValue,
      value: state.value,
    }),
    [state.error, state.loading, reset, setError, setValue, state.value]
  );
};

export default useLoadingValue;
