import MainLayout from "~/layouts/home/HomeMainLayout";
import { Box, Typography } from "@mui/material";
import useBaseStore from "~/libs/store/base";
import { DefaultBtn } from "~/components/common/IPButton";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { LottiePlayer } from "../common/LottieWeb";
import { FirebaseLogger } from "~/libs/ga";
import useEffectOnce from "react-use/lib/useEffectOnce";

const NoPageErrorPage = () => {
  const isMobile = useBaseStore((state) => state.isMobile);
  const navigate = useNavigate();

  useEffectOnce(() => {
    FirebaseLogger("error", {
      event: "no-page-error",
      error: location.pathname,
    });
  });

  return (
    <MainLayout>
      <StyledRoot>
        <StyledWrap>
          <StyledMainAnimation>
            <LottiePlayer
              file="layout/page-404"
              style={{
                width: isMobile ? 300 : 800,
                height: isMobile ? 180 : 400,
              }}
            />
          </StyledMainAnimation>
          <StyledMainText variant="h6" color="secondary">
            페이지를 찾을 수 없어요.{isMobile && <br />} 주소가 이동됐거나
            삭제된 것 같아요.
            <br />
            Please check that the Web site address is spelled correctly.
          </StyledMainText>
          <StyledSubmit>
            <DefaultBtn
              title={"Go Home"}
              onClick={() => {
                navigate("/");
              }}
            />
          </StyledSubmit>
        </StyledWrap>
      </StyledRoot>
    </MainLayout>
  );
};

const StyledRoot = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  position: "relative",
}));

const StyledWrap = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  [theme.breakpoints.down("md")]: { width: "100%" },
}));

const StyledMainAnimation = styled(Box)(({ theme }) => ({
  textAlign: "center",
  paddingBottom: theme.spacing(3),
  [theme.breakpoints.down("md")]: { paddingBottom: 0 },
}));

const StyledMainText = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontWeight: 600,
  [theme.breakpoints.down("md")]: {
    fontSize: theme.typography.subtitle2.fontSize,
  },
}));

const StyledSubmit = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  textAlign: "center",
}));

export default NoPageErrorPage;
