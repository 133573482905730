import { useMemo, useEffect } from "react";
import useLoadingValue from "~/hooks/useLoadingValue";
import { onAuthStateChanged } from "~/libs/db/firebase";

const useAuthState = (auth: any) => {
  const { error, loading, setError, setValue, value } = useLoadingValue(
    () => auth.currentUser
  );

  useEffect(() => {
    return onAuthStateChanged(auth, setValue, setError);
  }, [auth]);

  const resArray = [value, loading, error];
  return useMemo(() => resArray, resArray);
};

export default useAuthState;
