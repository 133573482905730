import React from "react";
import Header from "~/components/common/Header";
import Footer from "~/components/common/Footer";

/**
 * 홈화면 전체 레이아웃
 */

interface IProps {
  children: React.ReactNode;
}

const MainLayout = ({ children }: IProps) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default MainLayout;
