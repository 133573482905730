import { WarningOutlined } from "@mui/icons-material";
import { Box, Typography, Button } from "@mui/material";
import { FirebaseLogger } from "~/libs/ga";
import { useUserStore } from "~/libs/store";
import { userSignOut } from "~/libs/db/user";
import useEffectOnce from "react-use/lib/useEffectOnce";
import { logo } from "~/assets";

const CrashError = () => {
  const user = useUserStore((state) => state.user);

  const onHandleHome = () => {
    window.location.href = "/";
  };

  const onHandleHomeWithLogout = () => {
    if (user) {
      userSignOut()
        .then(() => {
          window.location.href = "/";
        })
        .catch((e) => {
          console.log("");
        });
    }
  };

  useEffectOnce(() => {
    FirebaseLogger("error", { event: "crash-error" });
  });

  return (
    <div
      style={{
        textAlign: "center",
        position: "relative",
        paddingTop: "20vh",
        minHeight: "100vh",
        minWidth: "100vw",
        background: "#ffffff",
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "inline-block",
          p: (theme) => theme.spacing(3),
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px;",
        }}
      >
        <Box
          sx={{
            "& img": { width: 150, maxWidth: "100%", height: "auto" },
          }}
        >
          <img src={logo} alt="impactus" />
        </Box>
        <WarningOutlined sx={{ fontSize: 50 }} color="error" />
        <Typography
          fontWeight={700}
          variant="h6"
          color="common.black"
          gutterBottom
          noWrap={false}
        >
          앗, 페이지를 불러오는데 오류가 있어요.
        </Typography>
        <Typography
          component="p"
          variant="subtitle1"
          color="common.black"
          noWrap={false}
          lineHeight={"125%"}
        >
          오류가 관리자에게 보고되었어요.
          <br />
          빠르게 버그를 고쳐놓을게요.
        </Typography>
        <Button
          variant="contained"
          color="error"
          sx={{ mt: (theme) => theme.spacing(3) }}
          onClick={onHandleHome}
        >
          <Typography
            component="span"
            variant="subtitle1"
            color="common.white"
            style={{ fontWeight: 700 }}
          >
            홈으로
          </Typography>
        </Button>
        {user && (
          <Button
            variant="contained"
            color="secondary"
            sx={{
              mt: (theme) => theme.spacing(3),
              ml: (theme) => theme.spacing(1),
            }}
            onClick={onHandleHomeWithLogout}
          >
            <Typography
              component="span"
              variant="subtitle1"
              color="common.white"
              style={{ fontWeight: 700 }}
            >
              로그아웃하고 새로고침
            </Typography>
          </Button>
        )}
      </Box>
    </div>
  );
};

export default CrashError;
