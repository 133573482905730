import { createTheme } from "@mui/material/styles";

/**
 * [색상 참고]
 * https://biotech-lab.tistory.com/entry/RGB%EC%99%80-%EC%98%88%EC%81%9C-%EC%83%89%EA%B9%94-%EC%BD%94%EB%93%9C
 */
const theme = createTheme({
  typography: {
    fontFamily: [
      '"Pretendard Variable"',
      "Pretendard",
      "Roboto Serif",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  palette: {
    primary: {
      dark: "#d2d2d2",
      main: "#ffffff",
      light: "#f2f4f6",
    },
    secondary: {
      dark: "#161616",
      main: "#282828",
    },
    error: {
      dark: "#c42e2e",
      main: "#d32f2f",
    },
    success: {
      main: "#00cd73",
    },
    warning: {
      dark: "#ffd400",
      main: "#ffc740",
      light: "#f3cf98",
    },
    info: {
      dark: "#007fff",
      main: "#3C86FF",
      light: "#f2f7ff",
    },
    divider: "#eaeaea",
  },
  // md: 모바일, 태블릿 portrait
  // lg: 태블릿 landscape
  // xl: 랩탑 small
  // 나머지(PC & Laptop large)
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

export const floatingAnimation = {
  "& .float-animation": {
    position: "relative",
    animation: "floating 2s infinite ease-in-out",
  },
  "@keyframes floating": {
    "0%": {
      transform: "translateY(-2px)",
    },
    "50%": {
      transform: "translateY(0)",
    },
    "100%": {
      transform: "translateY(-2px)",
    },
  },
};

export const fadeInAnimation = {
  "& .fade-in-animation": {
    animation: "fadein 2s ease-in-out",
  },
  "@keyframes fadein": {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
};

export const fadeOutAnimation = {
  "& .fade-in-animation": {
    animation: "fadeout 2s ease-in-out",
  },
  "@keyframes fadeout": {
    from: {
      opacity: 1,
    },
    to: {
      opacity: 0,
    },
  },
};

export default theme;

/**
 * beautiful color
#f7f9fa
#f2f7ff
#9ca3af
#0071C5
#00A1E9
#3C86FF
#48A1F1
#5FA8D3
#007FFF
#001E3C
#4d73ff
#f5f7ff
#f3cf98 (진한베이지)
#f5f5dc (베이지)
#FDB813 (노란색)
#db4455 (빨간색)
#ff0222 (빨간색)
#f44336
#7CB342 (연두색)
#9C27B0 (보라색)
#FF5722 (주황색)

// --colors-teal50: #d6fef1;
// --colors-teal100: #5cf8cf;
// --colors-teal200: #00ddb3;
// --colors-teal300: #00c1a2;
// --colors-teal400: #019d91;
// --colors-teal500: #007d7c;
// --colors-teal600: #006262;
// --colors-teal700: #004f4f;
// --colors-teal800: #014242;
// --colors-teal900: #002b2b;

// primary: { main: "#00ad97" },
// secondary: { main: "#3b82f6" },
// primary: { light: "#92bea9", main: "#659f7c", dark: "#4f7670" },
// primary: { main: "#00CD73" },
// secondary: { main: "#FFC740" },
*/
