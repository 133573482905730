import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { TypeOfFestival } from "~/types";

const store = () => ({
  isFestivalForm: false as boolean, // 페스티벌 등록 폼 열기
  festival: null as TypeOfFestival.FestivalResult | null, // 페스티벌 데이터
  festivalResultList: [] as TypeOfFestival.FestivalResult[] | [], // 페스티벌 리스트 데이터
  formImage: "", // 페스티벌 업로드 이미지
});

const useFestivalStore = create(import.meta.env.DEV ? devtools(store) : store);

export default useFestivalStore;
