import CryptoJS from "crypto-js";
import { sweetAlert } from "~/libs/utils";
import { TypeOfUser } from "~/types";

// 이메일 확인
export const emailValidation = (email: string) => {
  const emailRule =
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
  return emailRule.test(email);
};

// 패스워드확인
export const passwordValidation = (password: string) => {
  const numberRule = /[0-9]/i;
  const formatRule = /[a-z]/i;

  if (password?.length <= 5) {
    return "숫자+영문자 6자리 이상으로 조합해 주세요.";
  }
  if (!numberRule.test(password) || !formatRule.test(password)) {
    return "숫자와 영문자를 혼용하여야 합니다.";
  }

  return "ok";
};

// 텍스트 암호화
export const encryptionText = (value: string, key: string | undefined = "") => {
  const cipherText = CryptoJS.AES.encrypt(
    value,
    key || import.meta.env.VITE_SECRET_KEY
  ).toString();
  return cipherText;
};

// 텍스트 복호화
export const decryptionText = (value: string, key: string | undefined = "") => {
  const bytes = CryptoJS.AES.decrypt(
    value,
    key || import.meta.env.VITE_SECRET_KEY
  );
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

export const checkEmailVerified = async (
  user: TypeOfUser.User,
  navigate: (url: string) => void
) => {
  if (!user.emailVerified) {
    await sweetAlert.fire({
      title: "이메일 인증이 필요해요.",
      text: "인증이 완료된 이후에 이용할 수 있어요.",
      confirmButtonText: "확인",
      showCancelButton: false,
    });
    navigate("/user");
    return;
  }
};

// 특수기호 연락처 "-"자동 추가 제외하고 사용 불가
// REF: https://gurtn.tistory.com/m/86
export const autoValidPhoneNumber = (x: string) => {
  return x
    .replace(/[^0-9]/g, "")
    .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
    .replace(/(\-{1,2})$/g, "");
};

// 특수기호 "-" "_" 제외하고 사용 불가
// REF: https://orange056.tistory.com/89
export const isValidChar = (x: string) => {
  const regExp = /[ \{\}\[\]\/?.,;:|\)*~`!^\+┼<>@\#$%&\'\"\\\(\=]/gi;
  return regExp.test(x);
};

// 특수기호 "-" "_", 띄어쓰기 가능 제외하고 사용 불가
export const isValidCharTitle = (x: string) => {
  const regExp = /[\{\}\[\]\/?.,;:|\)*~`!^\+┼<>@\#$%&\'\"\\\(\=]/gi;
  return regExp.test(x);
};

// 올바른 URL인지 체크
export const isValidURL = (x: string) => {
  const regex =
    /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
  return regex.test(x);
};

// 한글만 있는지 체크
export const checkKorean = (x: string) => {
  const korean = /^[ㄱ-ㅎ|가-힣]+$/gi; // /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/gi;
  return korean.test(x);
};

// 한글 받침으로 끝나는지 여부 체크
/**
 *
 * @param word
 *
 * NOTE: usage
 * const adj = checkKoreanSyllableEnding(name) ? '이' : '가';
 * console.log (`야생의 ${name}${adj} 나타났다!`);
 */
export const checkKoreanSyllableEnding = (word: string) => {
  if (typeof word !== "string") return null;

  const lastLetter = word[word.length - 1];
  const uni = lastLetter.charCodeAt(0);

  if (uni < 44032 || uni > 55203) return null;

  return (uni - 44032) % 28 != 0;
};
