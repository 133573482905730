import axios, { AxiosInstance, AxiosResponse } from "axios";
import { useUserStore } from "../store";

const APIUri = import.meta.env.DEV
  ? `http://${import.meta.env.VITE_DEV_HOST}:1234`
  : import.meta.env.VITE_API_SERVER;

const axiosInstance: AxiosInstance = axios.create({
  baseURL: APIUri,
  headers: {
    "X-IMPACTUS-CLIENT": import.meta.env.VITE_SECRET_KEY as string,
    "Content-Type": "application/json",
    Referrer: "impactus.kr",
  },
  timeout: 10000,
});

// file upload
export const sendToFileStorage = async (timestamp: number, file: File) => {
  try {
    const frm = new FormData();
    frm.append("file", file, file.name);
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/storage/az/blob?&container=assets&timestamp=${timestamp}`,
      frm,
      { headers: { "Content-Type": "multipart/form-data" } }
    );

    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

// file get
export const getFileFromStorage = async (path: string) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.get(
      `/storage/az/blob?container=assets&path=${path}`,
      {
        headers: { "Content-Type": "application/json" },
      }
    );

    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

// file delete
export const deleteFileAtStorage = async (path: string) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.delete(
      `/storage/az/blob?container=assets&path=${path}`,
      {
        headers: { "Content-Type": "application/json" },
      }
    );

    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

type HWPType = {
  cardId: string;
  timestamp: number;
  src?: string;
  file?: File;
};

// get hwp to xhtml
export const getHWPToXhtml = async ({ cardId, src, timestamp }: HWPType) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/storage/hwp-to-xhtml`,
      { cardId, src, timestamp },
      {
        headers: {
          Authorization: `Bearer ${useUserStore.getState()?.user?.uid || ""}`,
        },
      }
    );

    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

// create hwp to xhtml
export const createHWPToXhtml = async ({
  cardId,
  timestamp,
  file,
}: HWPType) => {
  try {
    const frm = new FormData();
    frm.append("file", file, file.name);
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/storage/upload/hwp-to-xhtml?cid=${cardId}&timestamp=${timestamp}`,
      frm,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${useUserStore.getState()?.user?.uid || ""}`,
        },
      }
    );

    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};
