import { create } from "zustand";
import { devtools } from "zustand/middleware";

const store = () => ({
  analyze: null as any, // 탐구 활동 분석 결과
  analyzeList: [] as any, // 분석 기록
  isListShow: false as boolean, // 분석 기록 보기
  isSurveyOpen: false as boolean, // 서베이 오픈 여부
});

const useLLMStore = create(import.meta.env.DEV ? devtools(store) : store);

export default useLLMStore;
