import { memo, ReactElement } from "react";
import { useTheme } from "@mui/material/styles";
import { Tooltip, Typography } from "@mui/material";
import { renderHTML } from "~/libs/utils";

interface IProps {
  title?: string;
  children: ReactElement;
}

// 공통 스타일 툴팁
const IPTooltip = ({ title, children }: IProps) => {
  const theme = useTheme();

  if (!title) return null;
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          style: {
            maxWidth: "100vw",
            background: theme.palette.info.main,
            backgroundColor: theme.palette.info.main,
            border: `1px solid ${theme.palette.secondary.main}`,
          },
        },
      }}
      title={
        <Typography
          component="p"
          color="primary.main"
          variant="subtitle2"
          fontWeight={600}
        >
          {renderHTML(title || " ")}
        </Typography>
      }
    >
      {children || undefined}
    </Tooltip>
  );
};

export default memo(IPTooltip);
