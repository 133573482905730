import "./slide.css";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import HomeMainLayout from "~/layouts/home/HomeMainLayout";
import Main from "~/components/home/v2/Main";
import Product from "~/components/home/v2/Product";
import Curriculum from "~/components/home/v2/Curriculum";
import Video from "~/components/home/v2/Video";
import Review from "~/components/home/v2/Review";
import Solution from "~/components/home/v2/Solution";
import Partner from "~/components/home/v2/Partner";
import Customer from "~/components/home/v2/Customer";
import PageHelmet from "~/components/common/PageHelmet";
import { useEffect } from "react";
import { useBaseStore } from "~/libs/store";

const HomeContainer = () => {
  useEffect(() => {
    useBaseStore.setState({ title: "/" });
  }, []);

  return (
    <>
      <PageHelmet
        title="임팩터스 | 고교학점제 및 체험활동 전문 플랫폼"
        description="고교학점제 및 창의적 체험활동 전문 지식콘텐츠 플랫폼 임팩터스에서 고교학점제를 준비하세요!"
      />
      <HomeMainLayout>
        <StyledRoot>
          <Main />
          <Product />
          <Curriculum />
          <Solution />
          <Video />
          <Review />
          <Partner />
          <Customer />
        </StyledRoot>
      </HomeMainLayout>
    </>
  );
};

const StyledRoot = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  minHeight: "100vh",
}));

export default HomeContainer;
