import axios, { AxiosInstance, AxiosResponse } from "axios";
import { useUserStore } from "../store";
import { TypeOfSpace } from "~/types";

// Space API
const spaceAPIUri = import.meta.env.DEV
  ? `http://${import.meta.env.VITE_DEV_HOST}:1234`
  : import.meta.env.VITE_API_SERVER;

const axiosInstance: AxiosInstance = axios.create({
  baseURL: spaceAPIUri,
  headers: {
    "X-IMPACTUS-CLIENT": import.meta.env.VITE_SECRET_KEY as string,
    "Content-Type": "application/json",
    Referrer: "impactus.kr",
  },
  timeout: 60000,
});

// 스페이스 복제
export const spaceCopy = async (newCardList: TypeOfSpace.SpaceCard[]) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/space/copy`,
      {
        newCardList,
      },
      {
        headers: {
          Authorization: `Bearer ${useUserStore.getState()?.user?.uid || ""}`,
        },
      }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }

    return { status: "success" };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

// 스페이스 카드 복제
export const spaceCardCopy = async (
  cardId: string,
  newCard: TypeOfSpace.SpaceCard
) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/space/card/copy`,
      {
        cardId,
        newCard,
      },
      {
        headers: {
          Authorization: `Bearer ${useUserStore.getState()?.user?.uid || ""}`,
        },
      }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }

    return { status: "success" };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

// 스페이스 접속 기록 수정
export const spaceMultiHistoryUpdate = async (
  spaceId: string,
  postData: { uid: string; data: any }[]
) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/space/history/update`,
      { spaceId, data: postData },
      {
        headers: {
          Authorization: `Bearer ${useUserStore.getState()?.user?.uid || ""}`,
        },
      }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

// 스페이스 카드 수정 기록 목록 조회
export const spaceCardEditHistory = async (
  spaceId: string,
  postData: string[]
) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/space/card/history`,
      { spaceId, data: postData },
      {
        headers: {
          Authorization: `Bearer ${useUserStore.getState()?.user?.uid || ""}`,
        },
      }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

// 스페이스 카드 내용 리스트 조회
export const spaceCardListContent = async (postData: any[]) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/space/card/content/list`,
      { cards: postData },
      {
        headers: {
          Authorization: `Bearer ${useUserStore.getState()?.user?.uid || ""}`,
        },
      }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

// 스페이스 카드 내용 조회
// - 질문형 보드의 경우 formId 필요
export const spaceCardContent = async (
  cardId: string,
  type: string,
  formIds: string[] = null
) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/space/card/content`,
      { cardId, type, formIds },
      {
        headers: {
          Authorization: `Bearer ${useUserStore.getState()?.user?.uid || ""}`,
        },
      }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

// 스페이스 설문 전송
export const spaceQuestionTransfer = async (
  fromSpaceId: string,
  questionId: string,
  toSpaceId: string
) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/space/transfer/question`,
      { fromSpaceId, questionId, toSpaceId },
      {
        headers: {
          Authorization: `Bearer ${useUserStore.getState()?.user?.uid || ""}`,
        },
      }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

// 스페이스 보드 조회
export const spaceOwnBoardList = async (sid: string) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/space/own-board-list`,
      { sid },
      {
        headers: {
          Authorization: `Bearer ${useUserStore.getState()?.user?.uid || ""}`,
        },
      }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

// 스페이스 보드 전송
export const spaceBoardTransfer = async (
  fromSpaceId: string,
  board: any,
  toSpaceId: string
) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/space/transfer/board`,
      { fromSpaceId, board, toSpaceId },
      {
        headers: {
          Authorization: `Bearer ${useUserStore.getState()?.user?.uid || ""}`,
        },
      }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

// 스페이스 성취기준/평가기준 전송
export const spaceCriteriaTransfer = async (
  fromSpaceId: string,
  criteria: any,
  toSpaceId: string
) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/space/transfer/criteria`,
      { fromSpaceId, criteria, toSpaceId },
      {
        headers: {
          Authorization: `Bearer ${useUserStore.getState()?.user?.uid || ""}`,
        },
      }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

// 스페이스 카드 전송
export const spaceCardTransfer = async ({
  fromSpaceId,
  cardData,
  toBoardId,
  toSpaceId,
}) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/space/transfer/card`,
      {
        fromSpaceId,
        cardData,
        toBoardId,
        toSpaceId,
        uid: useUserStore.getState()?.user?.uid || "",
      },
      {
        headers: {
          Authorization: `Bearer ${useUserStore.getState()?.user?.uid || ""}`,
        },
      }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};
