import { memo } from "react";
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  Drawer,
  Typography,
  Box,
  Button,
} from "@mui/material";
import useBaseStore from "~/libs/store/base";
import Logo from "~/components/common/Logo";
import { useTheme } from "@mui/material/styles";
import { Close } from "@mui/icons-material";
import { FirebaseLogger } from "~/libs/ga";
import { styled } from "@mui/material/styles";
import { Link, useLocation } from "react-router-dom";
import Image from "./Image";
import { floatingAnimation } from "~/styles/theme";
import { rocket } from "~/assets";
import { useUserStore } from "~/libs/store";

/**
 * Mobile 헤더 메뉴
 *
 * 임팩터스 소개
 * 서비스 소개 > 스페이스 소개, 페스티벌 소개, 인사이트
 * 교육 커리큘럼 > 커리큘럼, 쇼케이스
 * 유튜브
 * 교육문의
 */

const NavigationDrawer = () => {
  const open = useBaseStore((state: any) => state.isDrawer);
  const categories = [
    {
      title: "임팩터스 소개",
      to: "/about",
    },
    {
      title: "스페이스 소개",
      to: "/about/space",
    },
    {
      title: "교육 프로그램",
      to: "/course/program",
    },
    {
      title: "교육 문의",
      to: "/course/form",
    },
    {
      title: "유튜브",
      to: "/course/youtube",
    },
  ] as { title: string; to: string }[];
  const theme = useTheme();
  const user = useUserStore((state) => state.user);
  const location = useLocation();

  const onClose = () => {
    useBaseStore.setState({ isDrawer: false });
  };

  const onHandleURL = (url: string) => {
    FirebaseLogger("click", {
      event: `MO-홈-내비-${url}`,
      timestamp: new Date().getTime(),
    });
    if (location.pathname === url) return;
    useBaseStore.setState({ isDrawer: false });
  };

  const onHandleSpace = () => {
    FirebaseLogger("click", {
      event: `MO-홈-내비-임팩트스페이스`,
      timestamp: new Date().getTime(),
    });
  };

  return (
    <StyledDrawer
      variant="temporary"
      anchor={theme.direction === "rtl" ? "right" : "left"}
      open={open}
      onClose={onClose}
      ModalProps={{ keepMounted: true }} // Better open performance on mobile.
      // drawerPaper: { position: "relative", width: "100%"  }
    >
      <StyledRoot>
        <StyledCloseIconButton onClick={onClose}>
          <Close />
        </StyledCloseIconButton>
        <StyledLogoWrap>
          <Logo />
        </StyledLogoWrap>
        <StyledMenuWrap>
          {categories.map((c: any) => (
            <StyledListItem
              onClick={() => {
                onHandleURL(c.to);
              }}
            >
              <ListItemText
                primary={
                  <Typography component="p" fontWeight={600}>
                    <Link to={c.to}>{c.title}</Link>
                  </Typography>
                }
              />
            </StyledListItem>
          ))}
          <ListItem sx={{ paddingTop: (theme) => theme.spacing(3) }}>
            <ListItemText
              primary={
                <StyledImpactSpaceWrap>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={onHandleSpace}
                    size="large"
                    startIcon={
                      <Image
                        className="float-animation"
                        src={rocket}
                        alt="스페이스로 이동"
                      />
                    }
                  >
                    <Typography
                      component="p"
                      variant="h6"
                      color="primary"
                      fontWeight={700}
                    >
                      <Link to={user ? "/space" : "/login"}>
                        임팩트 스페이스 가기
                      </Link>
                    </Typography>
                  </Button>
                </StyledImpactSpaceWrap>
              }
            />
          </ListItem>
        </StyledMenuWrap>
      </StyledRoot>
    </StyledDrawer>
  );
};

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiPaper-root": { width: "90%" },
}));

const StyledRoot = styled(Box)(({ theme }) => ({
  position: "relative",
}));

const StyledMenuWrap = styled(List)(({ theme }) => ({
  display: "block",
  position: "relative",
  paddingTop: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  minWidth: "100%",
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  position: "relative",
  marginTop: theme.spacing(1),
  color: theme.palette.secondary.main,
  "& p": { fontSize: theme.typography.h5.fontSize },
}));

const StyledCloseIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(2.25),
  right: theme.spacing(3.5),
  color: theme.palette.secondary.main,
  zIndex: 1000,
}));

const StyledLogoWrap = styled(ListItem)(({ theme }) => ({
  position: "relative",
  display: "block",
  top: "0.75rem",
  "& > img": { width: "150px !important" },
  marginTop: theme.spacing(1.75),
  marginLeft: theme.spacing(0.25),
}));

const StyledImpactSpaceWrap = styled(Box)(({ theme }) => ({
  ...floatingAnimation,
  fontWeight: 500,
  "& button img": {
    position: "relative",
    top: 5.5,
    width: 25,
    height: "auto",
  },
}));

export default memo(NavigationDrawer);
