import {
  firestore,
  spaceCollection,
  pinCodeCollection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  query,
  where,
  writeBatch,
  collection,
  addDoc,
  arrayUnion,
  increment,
  orderBy,
  startAfter,
  limit,
  QueryDocumentSnapshot,
  DBRef,
  set,
  update,
  database,
  deleteDoc,
} from "~/libs/db/firebase";
import { TypeOfSpace } from "~/types";
import { isEmpty } from "~/libs/utils";
import { units } from "~/utils";
import { getUserProfile } from "../ga";
import { SpaceQuestionDoc } from "~/types/SpaceFeature";
import { SpaceMemberProfile } from "~/data/space/constants";
import { spaceAPI } from "../api";

// Firestore DB 저장 시에 불필요하게 columns 저장되지 않도록 처리
export const deleteColumns = (space: any) => {
  const newSpace = JSON.parse(JSON.stringify(space));
  if (newSpace?.columns) {
    delete newSpace["columns"];
  }
  return { ...newSpace, ver: 2 };
};

// 스페이스 등록
export const spaceCreate = async (
  member: SpaceMemberProfile,
  space: TypeOfSpace.Space
): Promise<string | null> => {
  const batch = writeBatch(firestore);
  const id = doc(spaceCollection).id;
  const spaceRef = doc(firestore, "space", id);
  const spaceBackupRef = doc(firestore, "spaceBackup", id);
  const userRef = doc(firestore, "user", space.uid);

  batch.set(spaceRef, {
    id,
    ...space,
    permissionUser: [member],
    isArchive: false,
  });
  batch.set(spaceBackupRef, {
    id,
    uid: space.uid,
    create: space.create,
    isArchive: false,
  }); // 백업 데이터 저장
  batch.update(userRef, { "usage.space": increment(1) });
  await batch.commit();

  return id;
};

// 스페이스 수정
export const spaceUpdate = async (id: string, space: any): Promise<void> => {
  await setDoc(
    doc(spaceCollection, id),
    { id, ...deleteColumns(space) },
    { merge: true }
  );
};

// 스페이스 값 수정
export const spaceUpdateValue = async (
  id: string,
  value: any
): Promise<void> => {
  await setDoc(doc(spaceCollection, id), value, { merge: true });
};

// 스페이스 백그라운드 수정
export const spaceBGColorUpdate = async (
  id: string,
  bgColor: string,
  dataList: { uid: string; data: any }[]
): Promise<void> => {
  await spaceAPI.spaceMultiHistoryUpdate(id, dataList);
  await setDoc(doc(spaceCollection, id), { bgColor }, { merge: true });
};

// 스페이스 수정 / 히스토리 추가
export const spaceUpdateWithHistory = async (
  id: string,
  space: TypeOfSpace.Space,
  history: TypeOfSpace.SpaceHistory
): Promise<void> => {
  const batch = writeBatch(firestore);
  const spaceRef = doc(firestore, "space", id);
  const spaceHistoryRef = doc(firestore, "space", id, "history", history.id);

  batch.update(spaceRef, {
    ...deleteColumns(space),
    lastUpdate: history.create,
  });
  batch.set(spaceHistoryRef, { ...history, profile: getUserProfile() });

  await batch.commit();
};

// NOTE: 스페이스 히스토리 업데이트
export const spaceUpdateHistory = async (
  id: string,
  history: TypeOfSpace.SpaceHistory
): Promise<void> => {
  await setDoc(doc(collection(firestore, "space", id, "history"), history.id), {
    ...history,
    ver: 2,
  });
};

// NOTE: 스페이스 히스토리 업데이트 v2
export const spaceUpdateHistoryV2 = async (
  spaceId: string,
  history: TypeOfSpace.SpaceHistoryV2
): Promise<void> => {
  const id = doc(collection(firestore, "space", spaceId, "historyV2")).id;

  await setDoc(doc(collection(firestore, "space", spaceId, "historyV2"), id), {
    ...history,
    id,
    ver: 2,
  });
};

// 스페이스 수정 기록 가져오기 V2
export const spaceHistoryGetBySpaceIdV2 = async (
  spaceId: string,
  lastId: QueryDocumentSnapshot | null = null,
  page: number = 10
): Promise<TypeOfSpace.SpaceHistoryV2[] | [] | null> => {
  // 스페이스 기록 가져오기
  let q = null;
  if (lastId) {
    q = query(
      collection(firestore, `space`, spaceId, "historyV2"),
      orderBy("create", "desc"),
      startAfter(lastId),
      limit(page)
    );
  } else {
    q = query(
      collection(firestore, `space`, spaceId, "historyV2"),
      orderBy("create", "desc"),
      limit(page)
    );
  }
  const spaceHistoryDocs = await getDocs(q);
  if (spaceHistoryDocs.empty) return [];

  const spaceData = spaceHistoryDocs.docs.map((doc) => {
    return { id: doc.id, doc, data: doc.data() };
  }) as TypeOfSpace.SpaceHistoryV2[];

  return spaceData;
};

// NOTE: RTDB - 컬림 DND, 카드 DND
export const spaceColumnsManage = async (id: string, columns: any) => {
  const dbRef = DBRef(database, `spaceInfo/${id}`);
  await set(dbRef, { columns });
};

// NOTE: RTDB - 사용량 관리
export const spaceUsageManage = async (id: string, usage: any[]) => {
  const dbRef = DBRef(database, `spaceInfo/${id}`);
  await update(dbRef, { usage });
};

// 스페이스 삭제
export const spaceDelete = async (
  space: TypeOfSpace.Space,
  uid: string
): Promise<void> => {
  const batch = writeBatch(firestore);

  // 히스토리 먼저 삭제
  // const spaceHistoryRef = await getDocs(collection(firestore, "space", space.id, "history")); let promiseList = [] as any[]; if (!spaceHistoryRef.empty) { spaceHistoryRef.docs.forEach((item) => { promiseList.push(deleteDoc(item.ref)); }); } await Promise.all(promiseList);
  // 히스토리 먼저 삭제

  const spaceRef = doc(firestore, "space", space.id);
  const spaceBackupRef = doc(firestore, "spaceBackup", space.id);
  const userRef = doc(firestore, "user", uid);

  batch.update(spaceRef, { isArchive: true });
  batch.update(spaceBackupRef, { isArchive: true });
  batch.update(userRef, { "usage.space": increment(-1) });
  await batch.commit();
};

// 스페이스 uid로 가져오기
export const spaceGetByUid = async (
  uid: string
): Promise<TypeOfSpace.Space[] | null> => {
  if (isEmpty(uid)) return null;

  const q = query(
    spaceCollection,
    where("uid", "==", uid),
    where("isArchive", "==", false)
  );
  const spaceDoc = await getDocs(q);
  if (spaceDoc.empty) return null;

  const spaceData = spaceDoc.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });

  return spaceData;
};

// 스페이스 id로 가져오기
export const spaceGetById = async (
  id: string
): Promise<TypeOfSpace.Space | null> => {
  const result = await getDoc(doc(spaceCollection, id));
  if (!result.exists()) {
    return null;
  }

  return {
    id: result.id,
    ...result.data(),
  };
};

// 스페이스 PIN 코드 가져오면서 저장하기
export const spacePinCodeCreate = async (
  pin: string,
  uid: string
): Promise<null | true> => {
  const q = query(pinCodeCollection, where("pin", "==", pin));
  const spaceDoc = await getDocs(q);
  // 핀코드가 이미 있다면
  if (!spaceDoc.empty) return null;

  // 핀코드 생성
  await addDoc(pinCodeCollection, {
    uid,
    pin,
    create: new Date().getTime(),
  });

  return true;
};

// 스페이스 PIN 코드 가져오면서 저장하기
export const spaceGetPinCodeByCode = async (
  pin: string
): Promise<null | true> => {
  const q = query(pinCodeCollection, where("pin", "==", pin));
  const spaceDoc = await getDocs(q);
  // 핀코드가 이미 있다면
  if (!spaceDoc.empty) return null;

  // 없다면
  return true;
};

// 스페이스 생성하면서 PinCode 새로 저장
export const spacePinCodeCreateWithCode = async (
  uid: string,
  pin: string,
  sid: string,
  pinCodeId: string
): Promise<void> => {
  const batch = writeBatch(firestore);
  const timestamp = new Date().getTime();

  const pinRef = doc(firestore, "spacePinCode", pinCodeId);
  const spaceRef = doc(firestore, "space", sid);
  batch.set(pinRef, { uid, pin, timestamp }, { merge: true });
  batch.set(spaceRef, { pin }, { merge: true });
  await batch.commit();
};

// id가 같은 스페이스가 있는지 비교 후 오래된 내용 제거 후 마지막에 데이터 삽입
const checkLatestData = (
  newer: TypeOfSpace.SpaceUserLatest,
  latest: TypeOfSpace.SpaceUserLatest
) => {
  const filtered = latest.data.filter((data) => newer.sid !== data.sid);
  return [
    ...filtered,
    {
      sid: newer.sid,
      oid: newer.oid,
      uid: newer.uid,
      title: newer.title,
      create: newer.create,
    },
  ];
};

// 스페이스 최근 항목 등록
// 1. 값이 하나도 없다면 값 생성 및 추가
// 2. 값이 있다면 제일 최근에 집어넣은 값 확인
// 2-1. 사이즈 체크, 0.95MB 이하인지 체크
// 2-2. 0.95MB 이하라면 추가
// 2-3. 0.95MB 이상이라면 새로 생성
// 1~2 반복

// 관리자, 멘토/강사/선생은 아래 정보를 토대로 접속 정보 확인 가능하도록 진행
export const spaceLatestCreate = async (
  data: TypeOfSpace.SpaceUserLatest
): Promise<void> => {
  const latestList = await spaceGetLatest(data.uid);
  // 데이터가 없다면 새로 생성하기
  if (isEmpty(latestList)) {
    await addDoc(collection(firestore, "user", data.uid, "spaceLatest"), {
      data: [
        {
          sid: data.sid,
          oid: data.oid,
          uid: data.uid,
          title: data.title,
          create: data.create,
        },
      ],
      uid: data.uid,
      lastUpdate: data.lastUpdate,
    });
    return;
  }
  // 최근 리스트 사이즈 체크 추가 및 최신 업데이트 순으로 정렬
  const checkSizeList = latestList
    .map((latest) => {
      return {
        ...latest,
        size: units.calcObjectSize(latest, "MB"),
      };
    })
    .sort((a, b) => b.lastUpdate - a.lastUpdate);

  if (checkSizeList?.[0]?.size > 0.95) {
    // 0.95MB 이상될 경우에 새로 추가
    await addDoc(collection(firestore, "user", data.uid, "spaceLatest"), {
      data: [
        {
          sid: data.sid,
          oid: data.oid,
          uid: data.uid,
          title: data.title,
          create: data.create,
        },
      ],
      uid: data.uid,
      lastUpdate: data.lastUpdate,
    });
  } else {
    // 0.95MB 작으면 바로 업데이트
    const result = checkLatestData(data, checkSizeList[0]);

    await setDoc(
      doc(
        collection(firestore, "user", data.uid, "spaceLatest"),
        checkSizeList[0].id
      ),
      { data: result, lastUpdate: data.lastUpdate },
      { merge: true }
    );
  }
};

// 231114.v2 스페이스 최근 접속 기록 가져오기
export const spaceHistoryGetByUid = async (uid: string) => {
  if (isEmpty(uid)) return null;
  const spaceDocs = await getDocs(
    collection(firestore, "user", uid, "spaceHistory")
  );
  if (spaceDocs.empty) return null;
  const spaceDataList = spaceDocs.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });

  return spaceDataList;
};

// 231114.v2 스페이스 최근 접속 기록 하나 가져오기 - 이미 있으면 접속 시간만 체크해서 업데이트
export const spaceHistoryGetBySid = async (uid: string, sid: string) => {
  if (isEmpty(uid)) return null;
  const spaceDoc = await getDoc(
    doc(collection(firestore, "user", uid, "spaceHistory", sid))
  );
  if (!spaceDoc.exists) return null;
  const spaceData = { id: spaceDoc.id, ...spaceDoc.data() };

  return spaceData;
};

// 231114.v2 스페이스 최근 접속 기록 업데이트
export const spaceHistoryCreate = async (
  uid: string,
  data: TypeOfSpace.SpaceUserLatest
) => {
  if (isEmpty(data?.sid)) return;
  await setDoc(
    doc(collection(firestore, "user", uid, "spaceHistory"), data.sid),
    data,
    { merge: true }
  );
};

// 231114.v2 스페이스 최근 접속 기록 삭제
export const spaceHistoryDelete = async (uid: string, sid: string) => {
  await deleteDoc(doc(collection(firestore, "user", uid, "spaceHistory"), sid));
};

// NOTE: 관리자, 멘토/강사/선생은 아래 정보를 토대로 접속 정보 확인 가능하도록 진행
export const spaceLatestUpdate = async (
  spaceLatest: TypeOfSpace.SpaceUserLatest
): Promise<void> => {
  await setDoc(
    doc(
      collection(firestore, "user", spaceLatest.uid, "spaceLatest"),
      spaceLatest.id
    ),
    { data: spaceLatest.data, lastUpdate: spaceLatest.lastUpdate },
    { merge: true }
  );
};

// 최근 접속 기록 스페이스 전체 가져오기
export const spaceGetLatest = async (
  uid: string
): Promise<TypeOfSpace.SpaceUserLatest[] | [] | null> => {
  if (isEmpty(uid)) return null;
  const spaceLatestDocs = await getDocs(
    collection(firestore, "user", uid, "spaceLatest")
  );
  if (spaceLatestDocs.empty) return [];

  const spaceData = spaceLatestDocs.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  }) as TypeOfSpace.SpaceUserLatest[];

  return spaceData;
};

// 스페이스 참여자 멤버 추가
export const spaceMemberCreate = async (
  id: string,
  user: TypeOfSpace.PermissionUser
) => {
  await setDoc(
    doc(spaceCollection, id),
    { permissionUser: arrayUnion({ ...user, create: new Date().getTime() }) },
    { merge: true }
  );
};

// 스페이스 PIN 코드로 가져오기
export const spaceGetByPinCode = async (pin: string) => {
  const q = query(spaceCollection, where("pin", "==", pin));
  const spaceDoc = await getDocs(q);
  if (spaceDoc.empty) return null;

  const spaceData = spaceDoc.docs.map((doc) => {
    return {
      id: doc.id,
      ...doc.data(),
    };
  });

  return spaceData;
};

// 스페이스 수정 기록 가져오기
export const spaceHistoryGetBySpaceId = async (
  spaceId: string,
  lastId: QueryDocumentSnapshot | null = null,
  page: number = 10
): Promise<TypeOfSpace.SpaceHistory[] | [] | null> => {
  // 스페이스 기록 가져오기
  let q = null;
  if (lastId) {
    q = query(
      collection(firestore, `space`, spaceId, "history"),
      orderBy("create", "desc"),
      startAfter(lastId),
      limit(page)
    );
  } else {
    q = query(
      collection(firestore, `space`, spaceId, "history"),
      orderBy("create", "desc"),
      limit(page)
    );
  }
  const spaceHistoryDocs = await getDocs(q);
  if (spaceHistoryDocs.empty) return [];

  const spaceData = spaceHistoryDocs.docs.map((doc) => {
    return { id: doc.id, doc, data: doc.data() };
  }) as TypeOfSpace.SpaceHistory[];

  return spaceData;
};

// 스페이스 카피용 새로운 DocID 가져오기
export const getSpaceCopyID = () => doc(spaceCollection).id;

// 스페이스 카피하기
export const spaceCopy = async (
  id: string,
  space: TypeOfSpace.Space
): Promise<void> => {
  const batch = writeBatch(firestore);

  const spaceRef = doc(firestore, "space", id);
  const spaceBackupRef = doc(firestore, "spaceBackup", id);
  const userRef = doc(firestore, "user", space.uid);
  batch.set(spaceRef, { ...space, id });
  batch.set(spaceBackupRef, { id, create: space.create }); // 백업 데이터 저장
  batch.update(userRef, { "usage.space": increment(1) });
  await batch.commit();
};

// 스페이스 설문 복제
export const spaceQuestionCopy = async (
  id: string,
  questions: SpaceQuestionDoc[]
): Promise<void> => {
  for (const q of questions) {
    await setDoc(
      doc(firestore, "spaceBackup", id, "question", q.id),
      { ...q, sid: id },
      { merge: true }
    );
  }
};
