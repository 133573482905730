import { useState, useEffect } from "react";
import { asyncSafe, loadScriptWithKey, renderHTML } from "~/libs/utils";
import { useUserStore, useBaseStore, useSpaceStore } from "~/libs/store";
import useAuthState from "~/hooks/useAuthState";
import { auth, User } from "~/libs/db/firebase";
import { setUserByAnonym, userSetByUid } from "~/libs/db/user";
import {
  setUserIdByAnalytics,
  setUserPropertiesByAnalytics,
  getUserDeviceLog,
} from "~/libs/ga";
import { Loading } from "./Loading";
import { BrowserMessage } from "~/components/alert/AlertMessage";
import { Alert, Button, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Close } from "@mui/icons-material";
import { Toaster, toast } from "sonner";
import useNetworkState from "react-use/lib/useNetworkState";
import useEffectOnce from "react-use/lib/useEffectOnce";
import IPKakaoBtn from "~/components/common/IPKakaoBtn";
import { initialStatus } from "~/libs/store/space";
import useLocalStorage from "react-use/lib/useLocalStorage";
import { LOCAL_STORAGE_ENUM } from "~/libs/constants";
import SystemMaintenance from "./SystemMaintenance";

export default function Core() {
  const [user, loading, error] = useAuthState(auth); // Firebase auth
  const dataLoading = useBaseStore((state) => state.isLoading); // 전체 데이터 로딩
  const userData = useUserStore((state) => state.user); // 유저
  const isSignIn = useUserStore((state) => state.isSignIn); // 로그인 IP 저장
  const [initUser, setInitUser] = useLocalStorage(
    LOCAL_STORAGE_ENUM.SPACE_INIT_USER,
    null
  );

  const userInit = async (user: User) => {
    if (!user?.email) {
      setUserByAnonym(user.uid);
      return;
    }

    await asyncSafe(async () => {
      await userSetByUid(user);
      await setUserIdByAnalytics(user.uid);
      await setUserPropertiesByAnalytics();
      useUserStore.setState({ isAnonymous: false });
    }, "유저 정보를 불러오는데 오류가 있어요.<br/>잠시 후에 다시 시도해 주세요.");
  };

  useEffect(() => {
    if (!loading && !error) {
      if (user) {
        userInit(user)
          .then(() => {})
          .catch((e) => {});
      } else {
        useUserStore.setState({
          user: null,
          userAuth: null,
          isAnonymous: false, // 로그아웃 되면 처리!
        });
        useSpaceStore.setState({ ...initialStatus });
        setInitUser(null);
      }
    }
    return () => {};
  }, [loading, error, user]);

  useEffect(() => {
    if (userData && isSignIn) {
      useUserStore.setState({ isSignIn: false });
      // TODO: 신규 로그인 IP 업로드
      // userSignInIpAdd(userData.uid);
    }
    return () => {};
  }, [userData, isSignIn]);

  return (
    <>
      {dataLoading && <Loading />}
      <NotifyState />
      <NetworkState />
      <UIState />
      <CalcLocalStorage />
      <ConsoleLoggingForMobile />
      <CheckIsPWA />
      <IPKakaoBtn />
      <SystemMaintenance />
    </>
  );
}

// 네트워크 상태 체크
const NetworkState = () => {
  const { effectiveType: connection, online: isOnline } = useNetworkState(); // slow-2g, 2g, 3g, 4g

  useEffect(() => {
    if (!isOnline) {
      useBaseStore.setState({ networkState: "offline" });
      useBaseStore.setState({
        notify: {
          message:
            "네트워크 연결이 불가한 상태예요.<br/>서비스가 정상 동작하지 않아요.",
          option: { vertical: "top", horizontal: "center" },
        },
      });
      return;
    }
    if (connection) {
      useBaseStore.setState({ networkState: connection });
      if (connection === "2g" || connection === "slow-2g") {
        useBaseStore.setState({
          notify: {
            message:
              "저속 네트워크 연결 상태예요.<br/>일부 서비스가 정상 동작하지 않을 수 있어요.",
            option: { vertical: "top", horizontal: "center" },
          },
        });
      }
    }
    return () => {};
  }, [connection, isOnline]);

  return null;
};

// UI 체크
const UIState = () => {
  const [message, setMessage] = useState("");
  const isMobile = useMediaQuery("(max-width: 767px)"); // 모바일 여부
  const isMinTablet = useMediaQuery("(max-width: 900px)"); // 태블릿 세로 여부
  const isMinPC = useMediaQuery("(min-width: 1300px)"); // pc 너비 체크
  const isLandscape = useMediaQuery("(orientation: landscape)"); // ore react-use 사용 랜드스케이프

  useEffect(() => {
    const device = getUserDeviceLog();
    useBaseStore.setState({ device }); // 기기 정보 저장
    if (isMinTablet) {
      useBaseStore.setState({ isTablet: true });
    }

    if (
      isMinPC &&
      !device.deviceType?.includes("tablet") &&
      !device?.browserName?.toLowerCase?.()?.includes("chrome")
    ) {
      setMessage(`임팩터스 사이트는 크롬 브라우저에 최적화되어 있어요.`);
    }

    return () => {};
  }, [isMinTablet]);

  // 모바일 너비 체크
  useEffect(() => {
    useBaseStore.setState({ isMobile });
  }, [isMobile]);

  // landscape 체크
  useEffect(() => {
    useBaseStore.setState({ isLandscape });
  }, [isLandscape]);

  const browserCheck = () => {
    setMessage("");
  };

  if (!message) return null;
  return <BrowserMessage message={message} onClose={browserCheck} />;
};

// 공통 notify & alert
const NotifyState = () => {
  const isMobile = useMediaQuery("(max-width: 767px)"); // 모바일 여부
  const notify = useBaseStore((state) => state.notify); // 시스템 알림
  const theme = useTheme();
  // if (!notify || notify.message === "") return null;

  useEffect(() => {
    if (!notify || notify?.message === "") return;
    toast.custom((t) => (
      <Alert
        style={{
          position: "relative",
          zIndex: 9999,
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
          borderRadius: 5,
          border: `1px solid ${theme.palette.primary.dark}`,
        }}
        severity={notify.type || "error"}
        action={
          <Close
            fontSize="small"
            onClick={() => toast.dismiss(t)}
            sx={{
              cursor: "pointer",
              position: "absolute",
              top: 3,
              right: 3,
              color: theme.palette.secondary.dark,
            }}
          />
        }
      >
        <Typography
          component="p"
          variant="subtitle1"
          color="secondary.dark"
          sx={{ lineHeight: "120%", fontWeight: 400 }}
        >
          {renderHTML(notify.message)}
        </Typography>
        {notify?.onClick && (
          <Button
            variant="contained"
            color="secondary"
            onClick={notify?.onClick || undefined}
          >
            보기
          </Button>
        )}
      </Alert>
    ));
  }, [notify]);

  return (
    <Toaster
      expand
      visibleToasts={10}
      duration={3000}
      position={isMobile ? "bottom-center" : "bottom-right"}
    />
  );
};

// 로컬 브라우저 DB 사용량 체크
const CalcLocalStorage = () => {
  useEffect(() => {
    calcLocalStorage()
      .then(() => {})
      .catch((e) => {
        console.log("storage calc e: ", e);
      });
  }, [navigator?.storage]);

  // REF: https://han41858.tistory.com/54
  const calcLocalStorage = async () => {
    if (navigator?.storage && navigator?.storage?.estimate) {
      const quota = await navigator.storage.estimate();
      // quota.usage: 사용 중인 용량(byte) %
      // quota.quota: 앞으로 사용할 수 있는 전체 용량(byte)
      const percentageUsed = (quota.usage / quota.quota) * 100;
      const remaining = quota.quota - quota.usage;
      useBaseStore.setState({
        localStorageUsage: { percentageUsed, remaining },
      });
    }
  };

  return null;
};

// 모바일에서 로깅 보기
const ConsoleLoggingForMobile = () => {
  useEffectOnce(() => {
    const device = getUserDeviceLog();
    if (import.meta.env.DEV || location.host?.includes("localhost")) {
      if (
        device.deviceType?.includes("tablet") ||
        device.deviceType?.includes("mobile")
      ) {
        loadScriptWithKey(
          "eruda",
          "//cdn.jsdelivr.net/npm/eruda" as string,
          () => {
            const { eruda } = window as any;
            if (eruda) eruda.init();
          }
        );
      }
    }
  });

  return null;
};

// PWA 체크
const CheckIsPWA = () => {
  const searchParams = new URLSearchParams(location.search);
  const source = searchParams.get("source");

  // PWA 체크
  useEffectOnce(() => {
    if (source === "pwa") {
      useBaseStore.setState({ isStandalone: true });
    }
  });

  // PWA 체크
  useEffect(() => {
    const checkStandAlone = () => {
      const isPWA = ["fullscreen", "standalone", "minimal-ui"].filter(
        (displayMode) =>
          window?.matchMedia("(display-mode: " + displayMode + ")")?.matches
      );
      if (isPWA?.length > 0) {
        useBaseStore.setState({ isStandalone: true });
      }
    };
    window.addEventListener("DOMContentLoaded", checkStandAlone);

    return () => {
      window.removeEventListener("DOMContentLoaded", checkStandAlone);
    };
  }, []);

  return null;
};
