import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { TypeOfOpenEvaluate } from "~/types";

type HeaderInfo = {
  taskGroup: TypeOfOpenEvaluate.TaskGroup;
  task: TypeOfOpenEvaluate.Task;
};

type SearchOptionType = {};

export const initState = {
  taskGroupList: [] as TypeOfOpenEvaluate.TaskGroup[], // 학급 그룹 목록
  isTaskGroupCreateDialog: false as boolean, // 학급 생성 모달 show/hide
  isTaskGroupEditDialog: false as boolean, // 학급 수정 모달 show/hide
  isTaskCreateDialog: false as boolean, // 과제 생성 모달 show/hide
  isTaskEditDialog: false as boolean, // 과제 수정 모달 show/hide
  isQRCodeDialog: false as boolean, // QR코드 보기 show/hide

  searchOptions: null as any,
  headerInfo: {
    taskGroup: null,
    task: null,
  } as HeaderInfo,

  taskList: [] as TypeOfOpenEvaluate.Task[], // 과제 목록
  memberLis: [] as TypeOfOpenEvaluate.TaskMember[], // 구성원 목록

  taskGroupCntMenu: "" as string, // 그룹 - 현재 메뉴
  taskMangeCntMenu: "" as string, // 과제 - 현재 메뉴
  evalCntMenu: "" as string, // 평가 - 현재 메뉴
  editorIsYoutubeEmbed: false, // 에디터 유튜브 링크
  postList: [] as TypeOfOpenEvaluate.Post[],
};

const store = () => ({ ...initState });

const useOpenEvalStore = create(import.meta.env.DEV ? devtools(store) : store);

export default useOpenEvalStore;
