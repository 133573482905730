import axios, { AxiosInstance, AxiosResponse } from "axios";

export type onEmbedType = {
  type?: string;
  version?: string;
  title?: string;
  url?: string;
  provider_name?: string;
  description?: string;
  thumbnail_url?: string;
  thumbnail_width?: number;
  thumbnail_height?: number;
};

// REF:
// - https://noembed.com/
export const getURLInfoWithOnEmbedAPI = async (
  url: string
): Promise<any | null> => {
  try {
    const onEmbedRes = await axios.get<AxiosResponse>(
      `https://noembed.com/embed?url=${url}`,
      {
        timeout: 3000,
      }
    );
    if (parseInt(`${onEmbedRes.status / 100}`) === 2) {
      const res = onEmbedRes.data;
      return res;
    }
  } catch (e) {
    return null;
  }
};

const APIUri = import.meta.env.DEV
  ? `http://${import.meta.env.VITE_DEV_HOST}:1234`
  : import.meta.env.VITE_API_SERVER;

const axiosInstance: AxiosInstance = axios.create({
  baseURL: APIUri,
  headers: {
    "X-IMPACTUS-CLIENT": import.meta.env.VITE_SECRET_KEY,
    "Content-Type": "application/json",
    Referrer: "impactus.kr",
  },
  timeout: 20000, // 큰 파일 요청 및 지연시간이 긴 요청, 20초 대기
});

// REF:
// - https://github.com/itteco/iframely/tree/main?tab=readme-ov-file
export const getURLInfoWithSelfEmbedAPI = async (
  src: string
): Promise<any | null> => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/embed/info`,
      { src }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

// REF:
// - https://github.com/itteco/iframely/tree/main?tab=readme-ov-file
export const getURLWebRiskInfo = async (src: string): Promise<any | null> => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/embed/web-risk`,
      { src }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};
