import useEffectOnce from "react-use/lib/useEffectOnce";

interface IProps {
  title?: string;
  description?: string;
  url?: string;
}

const PageHelmet = ({ title, description, url }: IProps) => {
  const changeMetaDescription = (description: string | undefined) => {
    if (!description) return;
    const meta = document.getElementsByTagName("meta");
    for (let i = 0; i < meta.length; i++) {
      if (meta[i].name.toLowerCase().includes("description")) {
        meta[i].content = description;
      }
    }
  };

  const changeMetaURL = (url: string | undefined) => {
    if (!url) return;
    const meta = document.getElementsByTagName("meta");
    for (let i = 0; i < meta.length; i++) {
      if (meta[i].name.toLowerCase().includes("url")) {
        meta[i].content = url;
      }
    }
  };

  useEffectOnce(() => {
    document.title = title || "임팩터스 | 고교학점제 및 체험활동 전문 플랫폼";
    changeMetaDescription(
      description ||
        "고교 체험활동 전문 지식콘텐츠 플랫폼 임팩터스에서 고교학점제를 준비하세요!"
    );
  });

  return null;
};

export default PageHelmet;
