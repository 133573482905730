import "./styles/index.css";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import theme from "~/styles/theme";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { init, BrowserTracing, Replay } from "@sentry/react";
import posthog from "posthog-js";

if (import.meta.env.PROD) {
  try {
    init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [new BrowserTracing(), new Replay()],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  } catch (e) {
    console.warn("sentry init e: ", e);
  }

  try {
    posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
      api_host: "https://us.i.posthog.com",
      person_profiles: "identified_only",
    });
  } catch (e) {
    console.warn("post-hog js init e: ", e);
  }
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <ThemeProvider theme={theme}>
    <Router>
      <CssBaseline />
      <App />
    </Router>
  </ThemeProvider>
);
