import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { TypeOfEvaluate } from "~/types";

export const initialState = {
  // ---
  // 기등록된 DB 정보
  // ---
  evaluateUser: null as any, // 분석을 위해 선택된 참여자
  evalHistoryList: [] as any[], // 과거 관찰의견 생성 이력
  evalActivityList: [] as any[], // 활동기록 리스트
  evalCriteriaList: [] as any[], // 과거 성취기준/평가기준 생성 이력

  // ---
  // 초안 생성을 위한 데이터
  // ---
  evaluate: {
    activity: "", // 활동명
    activityDate: "", // 활동일자
    result: "", // 관찰의견 작성값
    review: "", // 총평/메모 작성값
    startDate: "", // 작성 시작일
    endDate: "", // 작성 종료일
    length: 0, // 작성 길이
    calcLength: {
      noBlank: 0,
      withBlank: 0,
      byte: 0,
    }, // 계산된 관찰의견 길이
    userActivity: [], // 선택된 활동 기록
    userCriteria: [], // 선택된 학생 평가기준 정보
    userCompetence: null, // 선택된 학생 역량 정보
  },
  isHoldCriteria: true as boolean, // 성취기준/평가기준 유지 여부
  isHoldCompetence: true as boolean, // 역량 유지 여부

  // ---
  // DB CRUD와 직접적인 연관이 없는 정보
  // ---
  isEdit: false as boolean, // 관찰의견 작성 중 여부
  criteriaList: [] as TypeOfEvaluate.Achievement[], // 성취기준표 목록 저장
  criteria: null as TypeOfEvaluate.Achievement, // 검색에서 선택된 성취기준 추가용

  // ---
  // 팝업&드로워 오픈
  // ---
  isAchievementDrawer: false as boolean, // 성취기준/평가기준 생성 Drawer show/hide
  isCriteriaPickDrawer: false as boolean, // 성취기준/평가기준 교육과정 목록 조회 Drawer show/hide
  isUserActivitySummary: false as boolean, // 활동요약 Dialog show/hide
  isRecordMakeHistory: false as boolean, // 관찰의견 작성기록
  isRecording: false as boolean, // AI 기반 관찰의견 작성 중 여부 체크

  // ---
  // 스페이스 표시용
  // ---
  spaceCriteriaList: [] as any[],
  // ---
};

const store = () => initialState;

const useEvaluateStore = create(import.meta.env.DEV ? devtools(store) : store);

export default useEvaluateStore;
