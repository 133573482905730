import * as diff from "diff";

// 문자열 차이 비교 초기화
window.diff = diff;

// 두 문자열의 차이점 반환
// REF:
// - https://codesandbox.io/s/react-jsdiff-l4ydo?file=/Diff.js
// - https://codesandbox.io/p/sandbox/react-jsdiff-diff-match-patch-react-diff-viewer-forked-6z0vud?file=%2FDiff.js
export const diffString = (
  string1: string = "",
  string2: string = "",
  mode: string = "characters"
) => {
  let groups = [];
  if (mode === "characters") {
    groups = diff.diffChars(string1, string2);
  }
  if (mode === "words") {
    groups = diff.diffWords(string1, string2);
  }
  if (mode === "lines") {
    groups = diff.diffLines(string1, string2, { newlineIsToken: true });
  }
  if (mode === "sentences") {
    groups = diff.diffSentences(string1, string2, { newlineIsToken: true });
  }

  const mappedGroups = groups.map((group) => {
    const { count, value, added, removed } = group;
    return {
      count: count || 0,
      value: value || "",
      added: added || false,
      removed: removed || false,
    };
  });

  return mappedGroups;
};

/**
 * REF: https://iskull-dev.tistory.com/160
 * @param object1
 * @param object2
 * @returns 두 객체 값 차이 비교
 */
export const compareIsDeepEqual = (object1: any, object2: any) => {
  const isObject = (object: any) => {
    return object != null && typeof object === "object";
  };

  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !compareIsDeepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
};

/**
 * REF: https://inpa.tistory.com/entry/JS-🚀-객체-끼리-값-비교
 *
 * @param data
 * @returns 객체 값 확인
 */
export const objectCompareWithKey = (data: any) => {
  return Object.keys(data)
    .sort()
    .reduce((obj, key) => ((obj[key] = data[key]), obj), {});
};
