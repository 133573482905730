import { Box, Typography } from "@mui/material";
import useBaseStore from "~/libs/store/base";
import { useNavigate } from "react-router-dom";
import { FirebaseLogger } from "~/libs/ga";
import { DefaultBtn } from "~/components/common/IPButton";
import { styled } from "@mui/material/styles";
import { LottiePlayer } from "~/components/common/LottieWeb";
import { bolt } from "~/assets";
import Image from "~/components/common/Image";

const Page = () => {
  const isMobile = useBaseStore((state) => state.isMobile);
  const navigate = useNavigate();

  const onNavigateForm = () => {
    FirebaseLogger("click", {
      event: "메인-도입문의",
      created: new Date().getTime(),
    });
    navigate("/course/form");
  };

  return (
    <StyledRoot>
      <StyledMainAnimation>
        <LottiePlayer
          file="page/main"
          style={{
            width: isMobile ? "100%" : 800,
            height: isMobile ? 245 : 475,
          }}
        />
      </StyledMainAnimation>
      <StyledMainText
        variant="h2"
        color="secondary"
        sx={{ lineHeight: "115%" }}
      >
        성장하는 학생들을 위한{isMobile && <br />} 탐구 교육 플랫폼
      </StyledMainText>
      {/* <StyledSubText variant="h5" color="secondary">학생 개인 맞춤형 교육의 시대. {isMobile && <br />}다양한 주제의 교육을{isMobile && <br />} 최고의 전문가에게 받아보세요. <br />{isMobile && <br />}임팩터스는 30년 이상 경력의{isMobile && <br />} 전문가들과 함께 합니다.</StyledSubText> */}
      {/* 교육 프로그램의 변화, 임팩터스와 함께 진로•주제탐구, 역량강화 수업, 임팩트 클래스 학생 성장•변화 기록의 공간, 임팩트 스페이스 */}

      <StyledSubText variant="h5" color="secondary">
        맞춤형 수업 설계부터 참여 학생 평가까지,{isMobile && <br />} 올인원
        솔루션을 제공합니다.
        {/* <br />탐구 수업에 필요한 모든 솔루션을 제공합니다. */}
        {/* 개인별 활동 기록을 관리할 수 있는 온라인 수업 환경에서<br />진로&middot;전공과 연계한 주제 탐구 수업을 제공합니다. */}
        {/* <br /> */}
        {/* 탐구 주제 설정부터 심화 탐구 설계까지 전문가와 함께해요. */}
        {/* 협동, 협력, 팀 프로젝트, 그룹 활동, 공동체 역량 강화 활동, 조별 활동,  */}
        {/* 올인원 솔루션,  */}
      </StyledSubText>

      <StyledSubmit>
        <DefaultBtn
          title="도입 문의하기"
          startIcon={<Image src={bolt} alt="도입 문의하기" />}
          onClick={onNavigateForm}
        />
      </StyledSubmit>
    </StyledRoot>
  );
};

const StyledRoot = styled(Box)(({ theme }) => ({
  paddingTop: "5vh",
  [theme.breakpoints.down("md")]: {
    paddingTop: 58,
  },
}));

const StyledMainAnimation = styled(Box)(({ theme }) => ({
  textAlign: "center",
  paddingBottom: 15,
  [theme.breakpoints.down("md")]: { paddingTop: 0 },
}));

const StyledMainText = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontWeight: 700,
  wordSpacing: -5,
  [theme.breakpoints.down("md")]: { fontSize: theme.typography.h4.fontSize },
}));

const StyledSubText = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontWeight: 600,
  paddingTop: 15,
  paddingBottom: 15,
  wordSpacing: -2,
  [theme.breakpoints.down("md")]: {
    paddingLeft: 5,
    paddingRight: 5,
    fontSize: theme.typography.h6.fontSize,
    lineHeight: "115%",
  },
}));

const StyledSubmit = styled(Box)(({ theme }) => ({
  textAlign: "center",
  fontWeight: 600,
}));

export default Page;
