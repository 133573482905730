import axios, { AxiosInstance, AxiosResponse } from "axios";
import { validator } from "~/utils";

/**
 * 1. 보안 관련 API - WebRisk(From Google)
 * 2. 노션 페이지 조회
 */

const APIUri = import.meta.env.DEV
  ? `http://${import.meta.env.VITE_DEV_HOST}:1234`
  : import.meta.env.VITE_API_SERVER;

const axiosInstance: AxiosInstance = axios.create({
  baseURL: APIUri,
  headers: {
    "X-IMPACTUS-CLIENT": import.meta.env.VITE_SECRET_KEY,
    "Content-Type": "application/json",
    Referrer: "impactus.kr",
  },
  timeout: 20000, // 큰 파일 요청 및 지연시간이 긴 요청, 20초 대기
});

// 노션 페이지 조회
export const getNotionPage = async (pageId: string) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.get(
      `/open-api/notion/${pageId}`
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

// 암호화된 파일 가져오기
export const getJsonFiles = async (fileId: string) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.get(
      `/open-api/files/${fileId}`
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    const result = validator.decryptionText(
      data?.result || "",
      import.meta.env.VITE_ENCODE_KEY
    );
    return { status: "success", result: JSON.parse(result) };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};
