import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { onSnapshot, collection, firestore, doc } from "~/libs/db/firebase";
import { useBaseStore } from "~/libs/store";

const SystemMaintenance = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(collection(firestore, "impactus"), "maintenance"),
      (snapshot) => {
        const data = snapshot.data();
        console.log("maintenance: ", data);
        if (data?.isMaintenance && checkURL()) {
          useBaseStore.setState({ systemMaintenance: data });
          navigate("/maintenance");
        }
      },
      (error) => {
        console.log("maintenance listen e: ", error);
      }
    );
    return () => unsubscribe();
  }, [location?.pathname]);

  const checkURL = () => {
    if (location?.pathname) {
      if (
        location.pathname === "/space" ||
        location.pathname.includes("/space/bookmark") ||
        location.pathname.includes("/space/collection") ||
        location.pathname.includes("/space/board") ||
        location.pathname.includes("/space/evaluate") ||
        location.pathname.includes("/space/user") ||
        location.pathname.includes("/login") ||
        location.pathname.includes("/festival") ||
        location.pathname.includes("/analyze") ||
        location.pathname.includes("/roadmap") ||
        location.pathname.includes("/coach") ||
        location.pathname.includes("/lecture") ||
        location.pathname.includes("/share/slide") ||
        location.pathname.includes("/task") ||
        location.pathname.includes("/verify") ||
        location.pathname.includes("/landing") ||
        location.pathname.includes("/space/template")
      ) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  return null;
};

export default SystemMaintenance;
