import { lazy as _lazy, ComponentType, Suspense } from "react";
import { Loading } from "./Loading";

// REF: - https://gist.github.com/ivanbtrujillo/005db52e4a8ef8fd1dc8f2db3b442d45
export const lazy = (
  factory: () => Promise<{ default: ComponentType<any> }>
) => {
  return _lazy(() => factory().catch(importErrorHandler));
};

export function importErrorHandler(err: string): {
  default: ComponentType<any>;
} {
  // Get the last reload time from local storage and the current time
  const timeStr = sessionStorage.getItem("last-reload");
  const time = timeStr ? Number(timeStr) : null;
  const now = Date.now();

  // If the last reload time is more than 10 seconds ago, reload the page
  const isReloading = !time || time + 10_000 < now;
  if (isReloading) {
    console.log("New version for this module found. Reloading ...");
    sessionStorage.setItem("last-reload", String(now));
    window.location.reload();
    return { default: () => null };
  }

  // We let ErrorBoundary handle the error
  throw new Error(err);
}

const loadable = (factory: Parameters<typeof lazy>[0]) => () => {
  const Component = lazy(factory);

  return (
    <Suspense fallback={<Loading />}>
      <Component />
    </Suspense>
  );
};

export default loadable;
