import useEffectOnce from "react-use/lib/useEffectOnce";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import { WarningOutlined } from "@mui/icons-material";
import { FirebaseLogger } from "~/libs/ga";

const AuthError = () => {
  const navigate = useNavigate();

  const onHandleHome = () => {
    navigate("/");
  };

  useEffectOnce(() => {
    // TODO: Logging
    FirebaseLogger("로그인", {
      event: "오류",
    });
  });

  return (
    <Box
      sx={{
        textAlign: "center",
        position: "relative",
        paddingTop: "20vh",
        minHeight: "100vh",
        background: "#ffffff",
      }}
    >
      <WarningOutlined style={{ fontSize: 50 }} color="primary" />
      <Typography
        component="h5"
        variant="h5"
        noWrap={false}
        color="primary.dark"
      >
        로그인 정보를 불러올 수 없어요
      </Typography>
      <Typography
        component="p"
        variant="subtitle2"
        color="primary"
        noWrap={false}
        gutterBottom
      >
        잠시 후에 다시 시도해 주세요.
      </Typography>
      <Typography
        component="p"
        variant="subtitle2"
        color="primary"
        noWrap={false}
        sx={{
          pt: (theme) => theme.spacing(2),
          pb: (theme) => theme.spacing(2),
        }}
      >
        Something went wrong. Please try again after a few minutes.
      </Typography>
      <Button variant="contained" color="secondary" onClick={onHandleHome}>
        <Typography
          component="span"
          variant="subtitle1"
          color="secondary"
          fontWeight={700}
        >
          홈으로
        </Typography>
      </Button>
    </Box>
  );
};

export default AuthError;
