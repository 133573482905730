import {
  CircularProgress,
  Box,
  Skeleton,
  Grid,
  Typography,
  LinearProgress,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { LottiePlayer } from "./LottieWeb";
import useBaseStore from "~/libs/store/base";
import { renderHTML } from "~/libs/utils";

export const Loading = ({
  title,
  bgcolor,
  bgcolorLoading,
}: {
  title?: string;
  bgcolor?: string;
  bgcolorLoading?: string;
}) => {
  const isMobile = useBaseStore((state) => state.isMobile);
  const theme = useTheme();

  return (
    <StyledLoadingWrap sx={{ bgcolor: bgcolor || "transparent" }}>
      <StyledLoading
        sx={{
          textAlign: "center",
          bgcolor: bgcolorLoading || "transparent",
          borderRadius: 2,
          p: theme.spacing(1),
        }}
      >
        <StyledProgress size={50} />
        {title && (
          <Typography
            component="p"
            variant={isMobile ? "subtitle1" : "h6"}
            color="common.black"
            fontWeight={600}
            sx={{
              width: "100%",
              lineHeight: "125%",
              pt: theme.spacing(1.5),
            }}
          >
            {renderHTML(title)}
          </Typography>
        )}
      </StyledLoading>
    </StyledLoadingWrap>
  );
};

export const LoadingWithAnimation = () => {
  const isMobile = useBaseStore((state) => state.isMobile);
  return (
    <StyledLoadingWrap bgcolor={(theme) => theme.palette.primary.main}>
      <StyledLoading>
        <LottiePlayer
          file="layout/book-loading"
          style={{
            width: isMobile ? "100%" : 420,
            height: isMobile ? "100%" : 390,
          }}
        />
      </StyledLoading>
    </StyledLoadingWrap>
  );
};

export const LoadingWithDataAnimation = ({ title }: { title?: string }) => {
  const isMobile = useBaseStore((state) => state.isMobile);
  const theme = useTheme();

  return (
    <StyledLoadingWrap bgcolor={(theme) => theme.palette.primary.main}>
      <StyledLoading sx={{ textAlign: "center" }}>
        <LottiePlayer
          file="layout/book-loading"
          style={{
            width: isMobile ? "100%" : 420,
            height: isMobile ? "100%" : 390,
          }}
        />
        {title && (
          <Typography
            component="p"
            variant={isMobile ? "subtitle1" : "h6"}
            color="common.black"
            fontWeight={600}
            sx={{
              width: "100%",
              lineHeight: "120%",
              pt: theme.spacing(1.5),
            }}
          >
            {title}
          </Typography>
        )}
      </StyledLoading>
    </StyledLoadingWrap>
  );
};

export const LoadingComponent = () => {
  const theme = useTheme();

  return (
    <Box
      className="loading-component"
      sx={{ textAlign: "center", bgcolor: "transparent" }}
    >
      <StyledProgress size={40} />
    </Box>
  );
};

// 로딩 스타일
const StyledLoadingWrap = styled(Box)(({ theme }) => ({
  position: "fixed",
  width: "100vw",
  height: "110vh",
  top: "-10%",
  zIndex: 9999,
  margin: 0,
  padding: 0,
}));

const StyledLoading = styled(Box)(({ theme }) => ({
  position: "absolute",
  margin: 0,
  padding: 0,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  [theme.breakpoints.down("md")]: {
    left: "50%",
    width: "100%",
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
}));

const StyledProgress = styled(CircularProgress)(({ theme }) => ({
  position: "relative",
  zIndex: 9999,
  color: theme.palette.error.main,
}));

interface ILinearLoadingProp {
  width?: string;
  color?: any;
}

export const LinearLoading = ({ width, color }: ILinearLoadingProp) => {
  return (
    <Box sx={{ width: width || "100%" }}>
      <LinearProgress color={color || "success"} />
    </Box>
  );
};
// 로딩 스타일

interface ILoadingWithSkeletonProps {
  width?: string | number;
  height?: string | number;
}

export const LoadingWithSkeleton = ({
  width,
  height,
}: ILoadingWithSkeletonProps) => {
  const isMobile = useBaseStore((state) => state.isMobile);
  return (
    <Skeleton
      variant="rectangular"
      width={width ? width : isMobile ? "100%" : 300}
      height={height ? height : isMobile ? 200 : 200}
    />
  );
};

export const LoadingWithMultipleSkeleton = () => {
  return (
    <Grid container sx={{ maxWidth: "100%", minWidth: "100%" }}>
      {/* Array.from({ length: 10 }, (v, i) => */}
      {[...new Array(10)].map((i, index) => {
        return (
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={3}
            xl={3}
            key={`${index}-loading-skeleton`}
          >
            <Box
              sx={{
                pt: (theme) => theme.spacing(0.5),
                pr: (theme) => theme.spacing(0.5),
              }}
            >
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={200}
                sx={{ background: "darkgrey" }}
              />
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};
