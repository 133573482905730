import { initializeApp } from "firebase/app";
import {
  getAnalytics,
  isSupported,
  logEvent,
  setUserId,
  setUserProperties,
} from "firebase/analytics";
import {
  getAuth,
  onAuthStateChanged,
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  User,
  ParsedToken,
  signOut,
  signInAnonymously,
} from "firebase/auth";
import {
  getFirestore,
  connectFirestoreEmulator,
  collection,
  addDoc,
  doc,
  getDoc,
  getDocs,
  deleteDoc,
  setDoc,
  updateDoc,
  query,
  where,
  limit,
  writeBatch,
  onSnapshot,
  onSnapshotsInSync,
  DocumentData,
  CollectionReference,
  arrayRemove,
  arrayUnion,
  increment,
  orderBy,
  startAfter,
  QueryDocumentSnapshot,
  DocumentReference,
  Query,
  refEqual,
  queryEqual,
} from "firebase/firestore";
import {
  getStorage,
  connectStorageEmulator,
  ref as storageRef,
  getDownloadURL,
  deleteObject,
  getMetadata,
} from "firebase/storage";
import {
  getDatabase,
  connectDatabaseEmulator,
  ref as DBRef,
  DataSnapshot,
  onDisconnect,
  set,
  update,
  onValue,
  off,
  push,
  child,
  serverTimestamp,
  remove,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  onChildMoved,
  // enableLogging,
} from "firebase/database";
import type {
  TypeOfCourseForm,
  TypeOfUser,
  TypeOfSpace,
  TypeOfLLM,
  TypeOfSpaceFeature,
  TypeOfOpenEvaluate,
  TypeOfCoupon,
  TypeOfTemplate,
} from "~/types";
import { getPerformance, trace } from "firebase/performance";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
// import { onMessage, getToken, getMessaging, isSupported as isMessagingSupported } from "firebase/messaging";

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTHDOMAIN,
  databaseURL: import.meta.env.DEV
    ? import.meta.env.VITE_FIREBASE_DEV_DATABASE_URL // -> localhost로만 설정할 때는 이 URL로!
    : import.meta.env.VITE_FIREBASE_DATABASE_URL,
  // databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

// if (import.meta.env.DEV) {
//   enableLogging(true);
// }

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
const database = getDatabase(app);
const analytics = isSupported().then((yes) => (yes ? getAnalytics(app) : null));
// const messaging = getMessaging(app);
const perf = getPerformance(app);

// NOTE: Check SSR issues
if (import.meta.env.PROD) {
  if (typeof window !== "undefined") {
    initializeAppCheck(app, {
      provider: new ReCaptchaV3Provider(
        import.meta.env.VITE_SITE_RECAPTCHA_V3_KEY as string
      ),
      isTokenAutoRefreshEnabled: true,
    });
  }
}

if (import.meta.env.DEV) {
  const hostPrefix = import.meta.env.VITE_DEV_HOST as string;
  connectAuthEmulator(auth, `http://${hostPrefix}:9099`);
  connectFirestoreEmulator(firestore, hostPrefix, 8030);
  connectStorageEmulator(storage, hostPrefix, 9199);
  connectDatabaseEmulator(database, hostPrefix, 9000);
}

const createCollection = <T = DocumentData>(collectionName: string) => {
  return collection(firestore, collectionName) as CollectionReference<T>;
};

const courseCollection = createCollection<
  TypeOfCourseForm.CourseForm | TypeOfCourseForm.SpaceForm
>("course");
const userCollection = createCollection<TypeOfUser.User>("user");
const spaceCollection = createCollection<TypeOfSpace.Space>("space");
const spaceBackupCollection =
  createCollection<TypeOfSpace.Space>("spaceBackup");
const cardCollection = createCollection<TypeOfSpace.SpaceCard>("spaceCard");
const alertCollection =
  createCollection<TypeOfSpaceFeature.SpaceCardAlert>("alert");
const pinCodeCollection =
  createCollection<TypeOfSpace.SpacePinCode>("spacePinCode");
const llmCollection = createCollection<TypeOfLLM.LLM>("llm");
const taskGroupCollection =
  createCollection<TypeOfOpenEvaluate.TaskGroup>("taskGroup");
const couponCollection = createCollection<TypeOfCoupon.Coupon>("coupon");
const templateCollection =
  createCollection<TypeOfTemplate.Template>("template");

export {
  app,
  firestore,
  database,
  DBRef,
  DataSnapshot,
  onDisconnect,
  onValue,
  update,
  set,
  off,
  push,
  remove,
  child,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  onChildMoved,
  serverTimestamp,
  analytics,
  logEvent,
  perf,
  trace,
  setUserId,
  setUserProperties,
  type ParsedToken,
  signOut,
  signInAnonymously,
  storage,
  storageRef,
  getDownloadURL,
  deleteObject,
  getMetadata,
  auth,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  // messaging,
  // isMessagingSupported,
  // onMessage,
  // getToken,
  collection,
  addDoc,
  doc,
  getDoc,
  getDocs,
  arrayUnion,
  arrayRemove,
  deleteDoc,
  setDoc,
  updateDoc,
  query,
  where,
  limit,
  writeBatch,
  onSnapshot,
  onSnapshotsInSync,
  increment,
  orderBy,
  startAfter,
  type DocumentData,
  QueryDocumentSnapshot,
  DocumentReference,
  CollectionReference,
  Query,
  refEqual,
  queryEqual,
  courseCollection,
  userCollection,
  spaceCollection,
  spaceBackupCollection,
  cardCollection,
  pinCodeCollection,
  llmCollection,
  alertCollection,
  taskGroupCollection,
  couponCollection,
  templateCollection,
};
export type { User };
