import { Box, Typography } from "@mui/material";
import { DefaultBtn } from "~/components/common/IPButton";
import { styled } from "@mui/material/styles";
import { FirebaseLogger } from "~/libs/ga";
import { useNavigate } from "react-router-dom";

const Customer = () => {
  const navigate = useNavigate();
  const onHandleNavigateForm = () => {
    FirebaseLogger("click", {
      event: "하단-도입문의",
      created: new Date().getTime(),
    });
    navigate("/course/form");
  };

  return (
    <StyledRoot>
      <Typography component="h4" variant="h2">
        이제,
        <br />
        새로운 교육
        <br />
        임팩터스를 만날 순간
      </Typography>
      <Box sx={{ paddingTop: (theme) => theme.spacing(1.5) }}>
        <DefaultBtn title="도입 문의하기" onClick={onHandleNavigateForm} />
      </Box>
    </StyledRoot>
  );
};

const StyledRoot = styled(Box)(({ theme }) => ({
  paddingTop: 100,
  paddingBottom: 130,
  background: "linear-gradient(to right, #f5f5dc, #b20a2c)",
  textAlign: "center",
  "& h4": { fontWeight: 700 },
  [theme.breakpoints.down("md")]: {
    paddingTop: 50,
    paddingBottom: 80,
    "& h4": { fontSize: theme.typography.h4.fontSize },
  },
}));

export default Customer;
