import { useEffect, useState } from "react";

function easeOutExpo(t: number): number {
  return t === 1 ? 1 : 1 - Math.pow(2, -10 * t);
}

export default function useCountNum(
  end: number,
  inViewport = false,
  start = 0,
  duration = 2000
) {
  const [isDone, setIsDone] = useState<boolean>(false);
  const [count, setCount] = useState(start);
  const frameRate = 1000 / 60;
  const totalFrame = Math.round(duration / frameRate);

  useEffect(() => {
    if (isDone) return;
    if (!inViewport) return;
    let currentNumber = start;
    const counter = setInterval(() => {
      const progress = easeOutExpo(++currentNumber / totalFrame);
      setCount(Math.round(end * progress));

      if (progress === 1) {
        clearInterval(counter);
        setIsDone(true);
      }
    }, frameRate);
  }, [inViewport, end, frameRate, start, totalFrame, isDone]);

  return count;
}
