import { Snackbar, Button, Typography, AlertColor } from "@mui/material";
import { Alert } from "@mui/lab";
import { renderHTML } from "~/libs/utils";

interface IAlertMessageProps {
  open: boolean;
  onClose: () => void;
  message?: string;
  type?: AlertColor;
  option?: any;
  timeout?: number;
}

const AlertMessage = (props: IAlertMessageProps) => {
  const { open, message, onClose, option, type, timeout } = props;

  return (
    <Snackbar
      autoHideDuration={timeout || 4000}
      open={open}
      onClose={onClose}
      key={"alert-notify"}
      anchorOrigin={
        option || {
          vertical: "bottom",
          horizontal: "center",
        }
      }
    >
      <Alert style={{ zIndex: 9999 }} severity={type || "error"}>
        <Typography
          variant="subtitle1"
          color="secondary.dark"
          sx={{ lineHeight: "120%", fontWeight: 400 }}
        >
          {renderHTML(message)}
        </Typography>
      </Alert>
    </Snackbar>
  );
};

export const BrowserMessage = (props: any) => {
  const { message, onClose } = props;

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      open={true}
      key={"browser-notify"}
      // onClose={() => {}}
    >
      <Alert
        style={{ zIndex: 9999 }}
        severity="error"
        action={
          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={onClose}
          >
            <Typography component="p" variant="body2" color="common.white">
              &times;
            </Typography>
          </Button>
        }
      >
        <Typography
          component="p"
          variant="subtitle2"
          sx={{ lineHeight: "120%", fontWeight: 400 }}
        >
          {message || ""}
        </Typography>
      </Alert>
    </Snackbar>
  );
};

export default AlertMessage;
