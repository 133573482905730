import "@dotlottie/react-player/dist/index.css";
import { DotLottiePlayer } from "@dotlottie/react-player";

interface IPlayerProps {
  file: string;
  style?: {
    width: string | number;
    height: string | number;
  };
}

export const LottiePlayer = ({ file, style }: IPlayerProps) => {
  return (
    <DotLottiePlayer
      src={`${import.meta.env.VITE_JAZZY_STATIC_HOST}/lottie/${file}.lottie`}
      autoplay
      loop
      style={{
        ...style,
        margin: "0 auto",
        textAlign: "center",
      }}
      renderer={"canvas"}
      rendererSettings={{
        preserveAspectRatio: "xMidYMid slice",
      }}
    />
  );
};
