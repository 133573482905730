import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { Rating } from "@mui/lab";
import HomeLayout from "~/layouts/home/HomeLayout";
import { GradientTypography } from "~/components/common/IPTypo";
import { styled } from "@mui/material/styles";
import Marquee from "react-fast-marquee";

// 👩‍🎓 👨‍🎓 👨 👴 👩 👵

const Review = () => {
  const [data, setData] = useState([
    {
      classTitle: "발효과학 클래스 후기",
      question: "",
      answer:
        "고등학생의 신분으로 대학 교수님의 강의를 이렇게 가까운 거리에서 듣는다는 게 쉽게 오는 기회가 아니잖아요. 교과 과정에서 다루는 수업 진도보다 더 넓은 범위에서, 생물학과 화학을 접할 수 있었다는 것. 그게 굉장히 의미 있었던 시간이라고 생각합니다.",
    },
    {
      classTitle: "발효과학 클래스 후기",
      question: "",
      answer:
        "일반적인 교과 수업에서는 교과서만 보죠. 그리고 학생들이 받아들이는 부분 또한 이론적인 영역에 국한되어 있어요. 글로만 수업을 하니 솔직히 이해가 안 될 때도 있어요. 하지만 발효과학 수업은 실험과 실습 같은 걸 하면서, 교수님의 설명도 더해지니 이해가 더 수월하였습니다.",
    },
    {
      classTitle: "기후위기 클래스 후기",
      question: "",
      answer:
        "다른 강의는 선생님이 앞에서 설명해주는 방식이 주를 이루는데, 이번에는 선생님이 직접 제시한 지식이나 자료를 기반으로 저희가 직접 조사하였습니다. 그런 과정 중에 영어로 된 자료도 번역기를 돌리면서 해석해 보고, 조사한 자료를 분석하여 저희들의 생각을 직접 적어보는 등 좀 뭔가 새로운 활동이었습니다.",
    },
    {
      classTitle: "기후위기 클래스 후기",
      question: "",
      answer:
        "정확하게 어느 방향으로 나아가야 할지에 대해서 조금 더 와닿았던 것 같습니다. 수업을 들으면서 진로 부분으로도 그렇고 앞으로 제가 진로 뿐만 아니라 삶을 살아가는데 어떤 마음을 가져야 될지에 대해서, 환경이 심각하다는 것만 알았는데 이번 기회에 나도 뭔가 바꿔봐야겠다는 생각을 했습니다.",
    },
    {
      classTitle: "데이터 사이언스 클래스 후기",
      question: "",
      answer:
        "제일 도움이 되었던 것은 프로그래밍, 엑셀 작업을 통해 데이터를 전처리 하고 시각화 하는 과정들이었습니다. 어떤 분야에서 무슨 활동을 하든지, 결국 제 주장을 펼칠 때에는 근거 자료가 필요하잖아요. 그 근거 자료에서 신빙성을 보여줄 수 있는 건 수치화된 자료라고 생각합니다.",
    },
    {
      classTitle: "데이터 사이언스 클래스 후기",
      question: "",
      answer:
        "이제까지 진행하였던 R&E 등 심화 프로그램은 항상 조사하는 것에 그쳤습니다. 때문에 더 빨리 찾아보고, 정리하는 게 우선적이었어요. 이번에는 데이터를 직접 찾아보고, 이 주장에는 이 데이터가 적합한지, 직접 비판적으로 해석해보고 가공하는 능력을 얻을 수 있었습니다.",
    },
    {
      classTitle: "임팩트메이커 클래스 후기",
      question: "",
      answer:
        "보고서 주제를 고르기 힘들었고 평소 진로와 관련되어 써야 하는데 주제를 정하는 것으로도 어려웠지만 이제 어떻게 잡아야 하는지 내가 탐구할 때 범위는 또 어떻게 잡아야 하는지 등 내가 앞으로 3년간 생활할 때 많은 도움이 될 수 있는 정보들로 가득찬 수업이었다",
    },
    {
      classTitle: "임팩트메이커 클래스 후기",
      question: "",
      answer:
        "이번 프로젝트는 다른 프로젝트와 달리 활동이 많아서 지루하지도 않고 진로에 대해 한발짝 다가갈 수 있는 시간을 마련해준것 같아서 너무너무 만족한다. 이런 프로젝트 한번 더 하고싶다.",
    },
    {
      classTitle: "임팩트메이커 클래스 후기",
      question: "",
      answer:
        "설명해주시는 선생님도 너무 잘 가르쳐서 마음에 들고 중간에 앱에 문제가 없는지 들어오신 선생님도 너무 마음에 들어요 앱에 무슨 문제가 없는지 걱정 되가지고 들어오셨던 거 같았는데 진짜 완전 마음에 들었어요",
    },
    {
      classTitle: "임팩트메이커 클래스 후기",
      question: "",
      answer:
        "탐구는 똑똑하고 멋진 사람만이 하는것 이라구 생각 했었는데 탐구의 정확한 뜻을 알고 ‘나도 탐구를 할 수 있는거구나’라고 생각하게 되었다",
    },
    {
      classTitle: "임팩트메이커 클래스 후기",
      question: "",
      answer:
        "원래 구체적으로 알아간다는 뜻이라 체험같은 경우도 탐구가 붙어있으면 지루하다고 생각이 들었었는데 오늘 탐구에 대해 자세히 알게되니 앞으로의 탐구활동은 재미있게 참여해야겠다.",
    },
  ]);

  return (
    <HomeLayout bgColor={"grey"}>
      <StyledTitleWrap>
        <Typography component="h3" variant="h3" color="secondary">
          임팩터스와 함께한 생생한
          <br />
          <GradientTypography title="현장의 목소리" />
        </Typography>
      </StyledTitleWrap>
      <StyledContent>
        <Marquee pauseOnHover={true}>
          {data.map((item) => {
            return (
              <StyledItem>
                <Typography component="p" variant="subtitle2" color="secondary">
                  {item.classTitle}
                </Typography>
                <Rating value={5} disabled />
                <Typography
                  component="h6"
                  variant="subtitle1"
                  color="secondary"
                >
                  {item.answer}
                </Typography>
              </StyledItem>
            );
          })}
        </Marquee>
      </StyledContent>
    </HomeLayout>
  );
};

const StyledTitleWrap = styled(Box)(({ theme }) => ({
  "& h3": {
    textAlign: "center",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: { fontSize: theme.typography.h5.fontSize },
  },
}));

const StyledContent = styled(Box)(({ theme }) => ({
  paddingTop: 50,
  verticalAlign: "top",
  '& [class^="marquee"]': {
    //Marquee 모듈의 속도 계산 오류로 80초 고정하기위해 important사용
    "animation-duration": "80s !important",
  },
}));

const StyledItem = styled(Box)(({ theme }) => ({
  display: "inline-block",
  background: theme.palette.primary.main,
  borderRadius: 5,
  padding: 10,
  border: `1px solid ${theme.palette.secondary.light}`,
  verticalAlign: "top",
  width: 350,
  height: 250,
  marginRight: theme.spacing(1),
  "& h5": {
    textWrap: "wrap",
    wordBreak: "break-all",
    fontWeight: 700,
    lineHeight: 1.25,
  },
  "& h6": {
    textWrap: "wrap",
    wordBreak: "break-all",
    fontWeight: 600,
    fontSize: theme.typography.subtitle1.fontSize,
    lineHeight: 1.35,
    padding: 10,
  },
  "& p": { wordBreak: "break-all", textWrap: "wrap" },
  [theme.breakpoints.down("xs")]: {
    "& p": { fontSize: theme.typography.caption.fontSize },
    "& h5": { fontSize: theme.typography.subtitle1.fontSize },
    "& h6": { fontSize: theme.typography.subtitle2.fontSize },
  },
}));

export default Review;
