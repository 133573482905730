import { logEvent, setUserId, setUserProperties } from "firebase/analytics";
import { useUserStore } from "./store";
import { analytics } from "./db/firebase";
import { getBrowserInfo } from "./utils";
import panel from "~/utils/mixpanel";
import posthog from "posthog-js";

// Firebase analytics REF
// [REF:] > 맞춤 이벤트 가감 + 이벤트 로깅 테스트 + 전자상거래 이벤트 / GDS 활용
// [REF:] > https://firebase.google.com/docs/analytics/events?platform=web&hl=ko
// [REF:] > https://firebase.google.com/docs/analytics/user-properties?hl=ko&platform=web

export const FirebaseLogger = async (id: string, content: any) => {
  try {
    const clientAnalytics = await analytics;
    const properties = getUserProperties();
    if (clientAnalytics) {
      logEvent(clientAnalytics, id, {
        ...properties,
        ...content,
        createdate: new Date().toLocaleString(),
        timestamp: content?.timestamp || new Date().getTime(),
      });
      if (import.meta.env.PROD) {
        const panelId = content?.event || id;
        panel.track(panelId, {
          ...properties,
          ...content,
          createdate: new Date().toLocaleString(),
          timestamp: content?.timestamp || new Date().getTime(),
        });
      }
    }
  } catch (e) {
    console.error("logging e: ", e);
  }
};

// 유저 id 애널리틱스 설정
export const setUserIdByAnalytics = async (uid: string) => {
  try {
    const clientAnalytics = await analytics;
    if (clientAnalytics) {
      setUserId(clientAnalytics, uid);
      if (import.meta.env.PROD) {
        panel.identify(uid);
      }
    }
  } catch (e) {
    console.error("l id e: ", e);
  }
};

// 유저 속성 애널리틱스 설정
export const setUserPropertiesByAnalytics = async () => {
  try {
    const clientAnalytics = await analytics;
    if (clientAnalytics) {
      const properties = getUserProperties();
      setUserProperties(clientAnalytics, properties);
      if (import.meta.env.PROD) {
        panel.people.set({ ...properties });
        posthog.identify(properties.uid, { email: properties.email });
      }
    }
  } catch (e) {
    console.error("l properties e: ", e);
  }
};

// NOTE: 인구통계학적 세그멘테이션 적용을 위한 로깅 정보
export const getUserProperties = () => {
  const user = useUserStore.getState().user;

  return {
    uid: user?.uid || "Unknown", // uid,
    displayName: user?.displayName || "Unknown", // 닉네임
    age: user?.age || "Unknown", // 연령대
    birthday: user?.birthday || "Unknown", // 생년월일
    gender: user?.gender || "Unknown", // 성별
    interest: user?.interest?.join?.(",") || "Unknown", // 관심사
    employer: user?.employer || "Unknown", // 소속명
    userType: user?.userType || "Unknown", // 소속(student|teacher|personal|organization)
    personalId: user?.personalId || "Unknown", // 학번/사번
    tier: user?.tier || "Unknown", // 티어(basic|pro|enterprize)
    type: user?.type || "Unknown", // 타입(관리자, 기본, 강사, 멘토 등)
    ip: user?.ip || "Unknown", // ip address
    provider: user?.provider || "Unknown", // 로그인 방법
    name: user?.name || "Unknown", // 성명
    email: user?.email || "Unknown", // 이메일
  };
};

// NOTE: 기기 정보 로깅 정보
export const getUserDeviceLog = () => {
  const info = getBrowserInfo();

  return {
    deviceModel: info?.device?.model || "Unknown", // e.g. iPad
    deviceType: info?.device?.type || "Unknown", // e.g. tablet
    deviceVendor: info?.device?.vendor || "Unknown", // e.g. Apple
    osName: info?.os?.name || "Unknown", // e.g. Mac OS
    osVersion: info?.os?.version || "Unknown", // e.g. 10.15.7
    browserName: info?.browser?.name || "Unknown", // e.g. Safari
    browserVersion: info?.browser?.version || "Unknown", // e.g. 16.4
    engineName: info?.engine?.name || "Unknown", // e.g. Webkit
    engineVersion: info?.engine?.version || "Unknown", // e.g. 605.1.15
    cpu: info?.cpu?.architecture || "Unknown", // e.g. Unknown
    ua: info?.ua || "Unknown", //  Mozilla/5.0 ~~
  };
};

// NOTE: 유저 기본 프로필 정보
export const getUserProfile = () => {
  const user = useUserStore.getState()?.user;

  return {
    uid: user?.uid || "Unknown", // uid,
    name: user?.name || "Unknown", // 성명
    email: user?.email || "Unknown", // 닉네임
    displayName: user?.displayName || "Unknown", // 닉네임
    age: user?.age || "Unknown", // 연령대
    birthday: user?.birthday || "Unknown", // 생년월일
    gender: user?.gender || "Unknown", // 성별
    interest: user?.interest || "Unknown", // 관심사
    employer: user?.employer || "Unknown", // 소속명
    profileImage: user?.profileImage || "Unknown", // 프로필 이미지
    userType: user?.userType || "Unknown", // 소속
    personalId: user?.personalId || "Unknown", // 학번/사번
    tier: user?.tier || "Unknown", // 티어
    profile: user?.profile || "Unknown", // 프로필
    type: user?.type || "Unknown", // 타입
    ip: user?.ip || "Unknown", // ip address
  };
};
