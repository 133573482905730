import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { TypeOfCoupon } from "~/types";

export const initialStatus = {
  adminCouponList: [], // 관리자 > 등록된 쿠폰 목록
  isCouponCreateDialog: false, // 관리자 > 쿠폰 등록
  isCouponEditDialog: false, // 관리자 > 쿠폰 수정
  editCoupon: null as TypeOfCoupon.Coupon, // 관리자 > 쿠폰 수정 정보
  isCouponEnrollList: false, // 쿠폰 등록자 정보 보기
};

const store = () => initialStatus;

const useMembershipStore = create(
  import.meta.env.DEV ? devtools(store) : store
);

export default useMembershipStore;
