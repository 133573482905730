import { useRef } from "react";
import { Box, Typography } from "@mui/material";
import { useInViewport } from "react-in-viewport";
import useCountUp from "~/hooks/useCountUp";

interface IProps {
  data?: number | undefined | null;
  unit?: string;
}

const Number = ({ data, unit }: IProps) => {
  const ref = useRef<any>(null);
  const { inViewport } = useInViewport(ref);
  const number = useCountUp(data, inViewport);

  return (
    <Box>
      <Typography
        ref={ref}
        variant="h4"
        color="black"
        fontWeight={600}
        sx={{ display: "inline-block" }}
      >
        {number}
      </Typography>
      <Typography
        variant="h5"
        color="black"
        fontWeight={500}
        sx={{ display: "inline-block" }}
      >
        {unit || "건"}
      </Typography>
    </Box>
  );
};

export default Number;
