import { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import useNetworkState from "react-use/lib/useNetworkState";
import { InfoOutlined } from "@mui/icons-material";
import { FirebaseLogger } from "~/libs/ga";
import useEffectOnce from "react-use/lib/useEffectOnce";
import { useUserStore } from "~/libs/store";
// import { userSignOut } from "~/libs/db/user";

// ChunkError Handling
function ChunkError() {
  const [networkStatus, setNetworkStatus] = useState<
    "offline" | "online" | null
  >(null);

  const network = useNetworkState();

  useEffect(() => {
    if (network && network.online !== undefined) {
      setNetworkStatus(network.online ? "online" : "offline");
    }
  }, [network]);

  // useEffect(() => { async function checkNetwork() { try { await fetch(import.meta.env.VITE_IP_API_HOST).then((response) => response.json()).then((json) => console.log(json)); return true; } catch (e) { return false; } } const fn = async () => { const online = await checkNetwork(); setNetworkStatus(online ? "online" : "offline"); }; fn(); }, [network.online]);

  if (networkStatus === null) return null;
  if (networkStatus === "online") return <YouNeedReload />;
  return <NetworkError />;
}

const YouNeedReload = () => {
  const user = useUserStore((state) => state.user);

  useEffectOnce(() => {
    FirebaseLogger("error", { event: "need-realod" });
  });

  const onHandleHome = () => {
    window.location.href = "/";
  };

  // const onHandleHomeWithLogout = () => { if (user) { userSignOut().then(() => { window.location.href = "/"; }).catch((e) => { console.log(""); }); } };

  return (
    <div
      style={{
        textAlign: "center",
        position: "relative",
        paddingTop: "20vh",
        minHeight: "100vh",
        minWidth: "100vw",
        background: "#ffffff",
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "inline-block",
          p: (theme) => theme.spacing(3),
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px;",
        }}
      >
        <InfoOutlined style={{ fontSize: 50 }} color="info" />
        <Typography
          component="h6"
          variant="h6"
          color="secondary.main"
          fontWeight={500}
          noWrap={false}
          lineHeight={"125%"}
        >
          사이트가 업데이트 됐어요.
          <br />
          새로고침이 필요해요.
        </Typography>
        <Button
          variant="contained"
          color="info"
          sx={{
            mt: (theme) => theme.spacing(3),
          }}
          onClick={onHandleHome}
        >
          <Typography
            component="span"
            variant="subtitle1"
            color="primary"
            fontWeight={700}
          >
            새로고침
          </Typography>
        </Button>
        {/* {user && (<Button color="secondary" sx={{ mt: (theme) => theme.spacing(3), ml: (theme) => theme.spacing(1) }} onClick={onHandleHomeWithLogout}><Typography component="span" variant="subtitle1" style={{ fontWeight: 700 }}>로그아웃하고 새로고침</Typography></Button> )} */}
      </Box>
    </div>
  );
};

const NetworkError = () => {
  return (
    <div
      style={{
        position: "fixed",
        background: "rgba(0, 0, 0, 0.75)",
        width: "100%",
        height: "100vh",
        zIndex: 9999,
      }}
    >
      <p
        style={{
          position: "absolute",
          zIndex: 9999,
          color: "#ffffff",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          margin: 0,
          padding: 0,
          textAlign: "center",
        }}
      >
        인터넷에 연결할 수 없어요.
        <br />
        네트워크 연결 상태를 확인하세요.
        <br />
        <br />
        Can not connect to the network.
        <br />
        Plz, Check your network status.
      </p>
    </div>
  );
};

export default ChunkError;
