import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

/**
 * 홈화면 섹션별 레이아웃
 */

interface IProps {
  children: React.ReactNode;
  bgColor?: string | undefined | null;
}

const HomeLayout = ({ children, bgColor }: IProps) => {
  return <StyledWrap bgColor={bgColor}>{children}</StyledWrap>;
};

const StyledWrap = styled(Box, {
  shouldForwardProp: (prop) => prop !== "bgColor",
})<{ bgColor?: string | undefined | null }>(({ theme, bgColor }) => ({
  marginTop: 50,
  marginBottom: 50,
  paddingTop: 100,
  paddingBottom: 100,
  background: bgColor ? "#f7f9fa" : theme.palette.primary.main,
  [theme.breakpoints.down("md")]: {
    paddingTop: 35,
    paddingBottom: 35,
    marginTop: 35,
    marginBottom: 35,
  },
}));

export default HomeLayout;
