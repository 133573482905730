import { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Typography,
  Dialog,
  DialogContent,
  IconButton,
  Box,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import useBaseStore from "~/libs/store/base";
import { version } from "~/libs/constants";
import { Link } from "react-router-dom";
import { FirebaseLogger } from "~/libs/ga";
import { venture, woman_biz, naver, youtube } from "~/assets";

const Footer = () => {
  const isMobile = useBaseStore((state) => state.isMobile);
  const [metaData, setMetaData] = useState(false);
  const [youthData, setYouthData] = useState(false);

  const onHandleMetaDataClick = () => {
    setMetaData(!metaData);
  };

  const onHandleYouthDataClick = () => {
    setYouthData(!youthData);
  };

  // const onHandleBusinessNumber = () => { window.open("http://www.ftc.go.kr/bizCommPop.do?wrkr_no=7950102423", "_blank"); };

  const onHandleOpenStatus = () => {
    window.open("https://impactus.statuspage.io/", "_blank");
  };

  const onHandleNavigate = (url: string) => {
    FirebaseLogger("click", { event: `푸터-${url}` });
    // navigate(url);
  };

  const onHandleOpenLink = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <>
      <Dialog
        fullScreen={isMobile}
        fullWidth
        maxWidth={"sm"}
        open={metaData}
        onClose={onHandleMetaDataClick}
        aria-labelledby="임팩터스 메타데이터 크롤링 금지"
        aria-describedby="임팩터스 메타데이터 크롤링 금지"
      >
        <DialogContent>
          <StyledBackButton
            onClick={onHandleMetaDataClick}
            aria-label="go-back"
            size="medium"
          >
            <Close fontSize="inherit" color="secondary" />
          </StyledBackButton>
          <Typography
            component="h6"
            variant="h6"
            gutterBottom
            color="secondary"
            fontWeight={600}
          >
            임팩터스 사이트 크롤링 금지
          </Typography>
          <Typography
            component="h6"
            variant="subtitle1"
            color="secondary"
            fontWeight={600}
          >
            [임팩터스 데이터베이스의 권리보호]
          </Typography>
          <Typography
            component="p"
            variant="subtitle2"
            color="secondary"
            gutterBottom
          >
            사이트 데이터 소스에서 수집된 데이터는 임팩터스의 고유한 수집/매칭
            시스템 및 인적 자원, 임팩터스 사용자의 참여를 통해 재가공되고
            있으며, 재가공된 콘텐츠 및 데이터에 대한 저작권과 소유권은 관련
            법령의 보호를 받고 있습니다.
          </Typography>
          <Typography
            component="h6"
            variant="subtitle1"
            color="secondary"
            fontWeight={600}
          >
            [지정되지 않은 방법을 통한 임팩터스 데이터베이스 무단 수집 거부]
          </Typography>
          <Typography
            component="p"
            variant="subtitle2"
            color="secondary"
            gutterBottom
          >
            impactus.kr과 파생 서비스를 통해 제공되는 모든 정보와 데이터의
            크롤링 및 무단 수집은 금지됩니다. 연구 등의 목적으로 임팩터스
            데이터가 필요하신 경우 sjmoon@impactus.kr을 통해 문의 바랍니다.
            임팩터스는 GCP를 통해 운영되고 있으며 크롤링한 IP는 로그에
            저장됩니다.
          </Typography>
          <Typography
            component="h6"
            variant="subtitle1"
            color="secondary"
            fontWeight={600}
          >
            [임팩터스의 콘텐츠 보호]
          </Typography>
          <Typography
            component="p"
            variant="subtitle2"
            color="secondary"
            gutterBottom
          >
            임팩터스의 모든 구성, 디자인, 데이터 등은 저작권법 등 관련 법률의
            보호를 받고 있으며, 이를 침해할 경우 저작권법 제2조, 제6조, 제 91조,
            제 93조, 제 136조, 부정경쟁방지법 등의 침해 및 위반, 형법 제314조
            제2항 업무방해죄, 정보통신망법 제50조 제2항 전자우편주소의 무단
            수집행위 등 금지 등에 해당될 수 있습니다.
          </Typography>
        </DialogContent>
      </Dialog>
      <Dialog
        fullScreen={isMobile}
        fullWidth
        maxWidth={"sm"}
        open={youthData}
        onClose={onHandleYouthDataClick}
        aria-labelledby="임팩터스 청소년 보호정책"
        aria-describedby="임팩터스 청소년 보호정책"
      >
        <DialogContent>
          <StyledBackButton
            onClick={onHandleYouthDataClick}
            aria-label="go-back"
            size="medium"
          >
            <Close fontSize="inherit" color="secondary" />
          </StyledBackButton>
          <Typography
            component="h6"
            variant="h6"
            gutterBottom
            color="secondary"
            style={{ fontWeight: 800 }}
          >
            임팩터스 청소년 보호정책
          </Typography>
          <Typography
            component="p"
            variant="subtitle2"
            color="secondary"
            gutterBottom
          >
            임팩터스("회사" 또는 "임팩터스"라 함)는 청소년이 건전한 인격체로
            성장할 수 있도록 하기 위하여 청소년 보호정책을 수립, 시행하고
            있습니다. 회사는 청소년들이 유해정보에 접근할 수 없도록 방지하고
            있는 바, 본 청소년 보호정책을 통하여 회사가 청소년보호를 위해 어떠한
            조치를 취하고 있는지 알려드립니다.
          </Typography>
          <Typography
            component="h6"
            variant="subtitle1"
            gutterBottom
            color="secondary"
            style={{ fontWeight: 800 }}
          >
            [1. 유해정보에 대한 청소년접근제한 및 관리조치]
          </Typography>
          <Typography
            component="p"
            variant="subtitle2"
            color="secondary"
            gutterBottom
          >
            회사는 청소년이 아무런 제한장치 없이 청소년 유해정보에 노출되지
            않도록 별도의 인증장치를 마련, 적용하며 청소년 유해정보가 노출되지
            않기 위한 예방차원의 조치를 강구합니다.
          </Typography>
          <Typography
            component="h6"
            variant="subtitle1"
            gutterBottom
            color="secondary"
            style={{ fontWeight: 800 }}
          >
            2. 유해정보로부터의 청소년보호를 위한 업무 담당자 교육 시행
          </Typography>
          <Typography
            component="p"
            variant="subtitle2"
            color="secondary"
            gutterBottom
          >
            회사는 정보통신업무 종사자를 대상으로 청소년보호 관련 법령 및
            제재기준, 유해정보 발견시 대처방법, 위반사항 처리에 대한 보고절차
            등을 교육하고 있습니다.
          </Typography>
          <Typography
            component="h6"
            variant="subtitle1"
            color="secondary"
            gutterBottom
            style={{ fontWeight: 800 }}
          >
            3. 유해정보로 인한 피해상담 및 고충처리
          </Typography>
          <Typography
            component="p"
            variant="subtitle2"
            color="secondary"
            gutterBottom
          >
            회사는 청소년 유해정보로 인한 피해상담과 고충처리를 위한 전문인력을
            배치하여 그 피해가 확산하지 않도록 하고 있습니다. 이용자분들께서는
            아래의 “청소년보호 책임자 및 담당자의 지정" 항을 참고하여
            이메일(E-mail)로 피해상담과 고충처리를 요청할 수 있습니다. 또한,
            유해정보로 인한 피해구제가 필요하신 경우에는 아래에 안내드리는
            관련기관에 문의하시기 바랍니다.
            <br />
            <br />
            - 방송통신심의위원회 : (02) 3219-5114 (www.kocsc.or.kr)
            <br />- 경찰청 아동ㆍ여성ㆍ장애인 경찰지원센터 : (국번없이) 182
            (www.safe182.go.kr)
            <br />
            <br />
          </Typography>
          <Typography
            component="h6"
            variant="subtitle1"
            gutterBottom
            color="secondary"
            style={{ fontWeight: 800 }}
          >
            4. 청소년보호 책임자 및 담당자의 지정
          </Typography>
          <Typography
            component="p"
            variant="subtitle2"
            color="secondary"
            gutterBottom
          >
            회사는 아래와 같이 청소년보호 책임자와 청소년보호 담당자를 지정하여
            청소년 유해정보를 차단·관리하며, 청소년 유해정보로부터 청소년을
            보호하는 정책을 수립하는 등 청소년 보호업무를 수행하고 있습니다.
          </Typography>
          <Typography
            component="p"
            variant="subtitle2"
            gutterBottom
            color="secondary"
            style={{ fontWeight: 700 }}
          >
            [청소년 보호책임자]
            <br />
            소속 및 성명 : 임팩터스 우연택 팀장
            <br />
            이메일 : ytwoo@impactus.kr
          </Typography>
          <Typography
            component="p"
            variant="subtitle2"
            gutterBottom
            color="secondary"
            style={{ fontWeight: 700 }}
          >
            [청소년 보호담당자]
            <br />
            소속 및 성명 : 임팩터스 박찬준 PM
            <br />
            이메일 : cjpark@impactus.kr
          </Typography>
          <br />
        </DialogContent>
      </Dialog>
      <StyledRoot>
        <Grid container>
          <StyledSubWrap item sm={12} xs={12}>
            <StyledText
              color="primary"
              onClick={() => {
                onHandleNavigate("/policy");
              }}
            >
              <Link to="/policy">이용약관</Link>
            </StyledText>
            <StyledText
              color="primary"
              onClick={() => {
                onHandleNavigate("/policy/privacy");
              }}
            >
              <Link to="/policy/privacy">개인정보 처리방침</Link>
            </StyledText>
            <StyledText color="primary" onClick={onHandleYouthDataClick}>
              청소년 보호정책
            </StyledText>
            <StyledText color="primary" onClick={onHandleMetaDataClick}>
              사이트 크롤링 금지
            </StyledText>
            <StyledText
              color="primary"
              onClick={() => {
                onHandleNavigate("/policy/license");
              }}
            >
              <Link to="/policy/license">오픈소스 라이선스</Link>
            </StyledText>
          </StyledSubWrap>
          <Grid item sm={12} xs={12}>
            <StyledContentWrap>
              <StyledContentText gutterBottom>
                상호: 주식회사 임팩터스 | 대표이사: 김보경 {!isMobile && `|`}{" "}
                {isMobile && <br />} 사업자 등록번호: 590-87-02574 <br />
                주소: 서울특별시 용산구 서빙고로 17, 4층 15호 임팩터스
                <br />
                <br />
                {/* 통신판매업신고: 2022-서울성북-1248호<br /> */}
                고객센터: 0507-1855-3773
              </StyledContentText>
              {/* <StyledContentText style={{ cursor: "pointer", textDecoration: "underline", display: "inline-block" }} onClick={onHandleBusinessNumber} gutterBottom>통신판매업 사업자 정보 확인</StyledContentText> */}
              <StyledContentText>
                문의사항: sjmoon@impactus.kr {!isMobile && `|`}{" "}
                {isMobile && <br />} 개인정보관리책임자: 연구개발팀 문성재
                <br />
              </StyledContentText>
            </StyledContentWrap>
          </Grid>
          <Grid item xs={12}>
            <StyledContentWrap>
              <StyledCopyrightText align="center">
                호스팅 서비스 제공자: Google, Inc.
              </StyledCopyrightText>
              <StyledCopyrightText align="center" gutterBottom>
                Copyright © IMPACTUS Co., Ltd. All rights reserved. Since 2021.
              </StyledCopyrightText>
              <StyledCopyrightText align="center" gutterBottom>
                ver.{version}
              </StyledCopyrightText>
              <StyledCopyrightText align="center" gutterBottom>
                <Typography
                  component="span"
                  sx={{ cursor: "pointer" }}
                  onClick={onHandleOpenStatus}
                >
                  시스템 상태
                </Typography>
              </StyledCopyrightText>
              {import.meta.env.PROD && (
                <>
                  <Typography
                    component="p"
                    variant="subtitle2"
                    sx={{
                      marginTop: 5,
                      display: "inline-block",
                    }}
                  >
                    <a
                      href="https://hits.seeyoufarm.com"
                      style={{
                        display: "inline-block",
                        width: "100%",
                      }}
                    >
                      <img
                        src="https://hits.seeyoufarm.com/api/count/incr/badge.svg?url=https%3A%2F%2Fimpactus.kr%2Fhit-counter&count_bg=%23C83D3D&title_bg=%23FF0000&icon=&icon_color=%23E7E7E7&title=hits&edge_flat=false"
                        alt="impactus-hits"
                      />
                    </a>
                  </Typography>
                </>
              )}
            </StyledContentWrap>
          </Grid>
          <Grid item xs={12}>
            <StyledBottom>
              <StyledVenture>
                <img src={venture} alt="벤처기업인증" />
                <img src={woman_biz} alt="여성기업인증" />
              </StyledVenture>
              <Box className="footer-logo">
                <img
                  src={naver}
                  alt="임팩터스 네이버 블로그"
                  className="naver-blog-icon"
                  onClick={() => {
                    onHandleOpenLink("https://blog.naver.com/impactus");
                  }}
                />
                <img
                  src={youtube}
                  alt="임팩터스 유튜브"
                  className="youtube-icon"
                  onClick={() => {
                    onHandleOpenLink(
                      "https://www.youtube.com/channel/UCm9TRxsJvghqQmQhBsm6KNA"
                    );
                  }}
                />
              </Box>
            </StyledBottom>
          </Grid>
        </Grid>
      </StyledRoot>
    </>
  );
};

const StyledRoot = styled(Box)(({ theme }) => ({
  position: "relative",
  borderTop: `1px solid ${theme.palette.divider}`,
  background: theme.palette.secondary.light,
  width: "100%",
  padding: "1px 0",
  paddingTop: theme.spacing(5),
  overflowWrap: "break-word",
}));

const StyledBottom = styled(Box)(({ theme }) => ({
  background: theme.palette.divider,
  textAlign: "center",
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  "& .footer-logo > img": {
    cursor: "pointer",
  },
  "& .footer-logo > .naver-blog-icon": {
    width: 30,
    height: 30,
    marginRight: 5,
  },
  "& .footer-logo > .youtube-icon": {
    position: "relative",
    top: 7,
    width: 35,
    height: 45,
  },
}));

const StyledVenture = styled(Box)(({ theme }) => ({
  "& > img": {
    width: 150,
    height: 60,
    border: `1px solid ${theme.palette.secondary.light}`,
    padding: theme.spacing(1),
    background: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
  },
  [theme.breakpoints.down("sm")]: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    position: "relative",
    left: 0,
    bottom: 0,
    "& img": { width: 120, height: 50 },
  },
}));

const StyledSubWrap = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  textAlign: "center",
  [theme.breakpoints.down("md")]: { marginBottom: 10 },
  "& a": { color: theme.palette.primary.main },
}));

const StyledBackButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: "1rem",
  top: ".25rem",
}));

const StyledText = styled(Typography)(({ theme }) => ({
  background: theme.palette.secondary.light,
  color: theme.palette.primary.light,
  fontSize: theme.typography.h6.fontSize,
  fontWeight: 600,
  display: "inline-block",
  margin: 5,
  padding: 3,
  "&:hover": { cursor: "pointer" },
  [theme.breakpoints.down("md")]: {
    margin: 3.5,
    fontSize: theme.typography.subtitle2.fontSize,
  },
}));

const StyledContentWrap = styled(Box)(({ theme }) => ({
  background: theme.palette.secondary.light,
  maxWidth: 1280,
  margin: "auto",
  textAlign: "center",
  paddingBottom: theme.spacing(1),
  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
    margin: "auto 10px",
    fontSize: theme.typography.subtitle2.fontSize,
  },
}));

const StyledContentText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.light,
  fontSize: theme.typography.subtitle1.fontSize,
  [theme.breakpoints.down("md")]: {
    fontSize: theme.typography.subtitle2.fontSize,
  },
}));

const StyledCopyrightText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.light,
  fontSize: theme.typography.subtitle1.fontSize,
  fontWeight: 500,
  [theme.breakpoints.down("md")]: {
    fontSize: theme.typography.caption.fontSize,
  },
}));

export default Footer;
