import axios, { AxiosResponse } from "axios";
import { getUserDeviceLog } from "~/libs/ga";

export type IPType = {
  ip: string;
  country: string;
  timezone: any;
  orgName: string;
  region: string;
  latitude: string;
  longitude: string;
  create: number;
  ver: string;
  info?: any;
};

/**
 * - External ip 확인
 * - 여기도 사용 가능: https://ip-api.com/json
 * > 단, 변수명이 다름
 */

export const getIp = async (): Promise<IPType | null | string> => {
  if (import.meta.env.DEV) return null;
  const info = getUserDeviceLog();

  try {
    const cloudflareRes = await axios.get<AxiosResponse>(
      import.meta.env.VITE_IP_API_HOST,
      {
        timeout: 3000,
      }
    );
    if (parseInt(`${cloudflareRes.status / 100}`) === 2) {
      const geoIp = cloudflareRes.data;
      return {
        ip: geoIp?.["ip"] || "unknown",
        country: geoIp?.["country_code"] || "unknown",
        timezone: geoIp?.["time_zone"] || "unknown",
        orgName: geoIp?.["connection"] || "unknown",
        region: geoIp?.["region_name"] || "unknown",
        latitude: geoIp?.["latitude"] || "unknown",
        longitude: geoIp?.["longitude"] || "unknown",
        create: new Date().getTime() as number,
        ver: "ip-worker",
        info,
      };
    }
  } catch (e) {
    if (axios.isAxiosError(e)) {
      return e?.message;
    }
    return e;
  }

  try {
    const res = await axios.get<AxiosResponse>(
      import.meta.env.VITE_IP_GEOJS_HOST,
      {
        timeout: 3000,
      }
    );
    if (parseInt(`${res.status / 100}`) === 2) {
      const geoIp = res.data;
      return {
        ip: geoIp?.["ip"] || "unknown",
        country: geoIp?.["country_code"] || "unknown",
        timezone: geoIp?.["timezone"] || "unknown",
        orgName: geoIp?.["organization_name"] || "unknown",
        region: geoIp?.["region"] || "unknown",
        latitude: geoIp?.["latitude"] || "unknown",
        longitude: geoIp?.["longitude"] || "unknown",
        create: new Date().getTime() as number,
        ver: "geo-js",
        info,
      };
    }
  } catch (e) {
    if (axios.isAxiosError(e)) {
      return e?.message;
    }
    return e;
  }

  try {
    const retry = await axios.get<AxiosResponse>(
      import.meta.env.VITE_IP_IPINFO_HOST,
      { timeout: 3000 }
    );
    if (parseInt(`${retry.status / 100}`) === 2) {
      const geoIp = retry.data;
      return {
        ip: geoIp?.["ip"] || "unknown",
        country: geoIp?.["country"] || "unknown",
        timezone: geoIp?.["timezone"] || "unknown",
        orgName: geoIp?.["org"] || "unknown",
        region: geoIp?.["region"] || "unknown",
        latitude: geoIp?.["loc"]?.split(",")?.[0] || "unknown",
        longitude: geoIp?.["loc"]?.split(",")?.[1] || "unknown",
        create: new Date().getTime() as number,
        ver: "ip-info",
        info,
      };
    }
  } catch (e) {
    if (axios.isAxiosError(e)) {
      return e?.message;
    }
    return e;
  }

  return null;
};
