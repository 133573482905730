import axios, { AxiosInstance, AxiosResponse } from "axios";

const APIUri = import.meta.env.VITE_JAZZY_STATIC_HOST;

const axiosInstance: AxiosInstance = axios.create({
  baseURL: APIUri,
  headers: { "Content-Type": "application/json" },
  timeout: 30000,
});

export const getJazzyStatic = async (fileName: string) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.get(
      `/${fileName}`
    );
    if (status >= 500) {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return { status: "success", result: data };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};
