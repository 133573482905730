import { memo } from "react";
import LazyLoad from "react-lazyload";

interface IProps {
  src: any;
  className?: any;
  alt?: string;
  isLazy?: boolean;
}

const Image = ({ src, className, alt, isLazy = true }: IProps) => {
  if (isLazy) {
    return (
      <LazyLoad>
        <img src={src} alt={alt || "IMPACTUS"} className={className || ""} />
      </LazyLoad>
    );
  } else {
    <img src={src} alt={alt || "IMPACTUS"} className={className || ""} />;
  }
};

export default memo(Image);
