import { create } from "zustand";
import { devtools } from "zustand/middleware";

type State = {
  shapeType: string;
  viewerList: any[];
  galleryList: any[];
};

type Action = {
  setShapeType: (shapeType: State["shapeType"]) => void;
};

const initialStatus = {
  shapeType: "default",
  viewerList: [], // 카드별 파일 & 이미지 & 영상 뷰어 정보
  galleryList: [], // 전체 파일 & 이미지 & 영상 뷰어 정보
  // isArtEdit: false, // 아트 editor show/hide
  // artData: null, // 아트(fabric type)
  // artHistory: [], // 아트 기록(fabric type)
};

// 보기모드 관리
const store = create<State & Action>()(
  devtools((set, get) => ({
    ...initialStatus,
    setShapeType: (shapeType: string) => set((state) => ({ shapeType })),
  }))
);

export default store;
