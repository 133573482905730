import { Grid, Box, Typography } from "@mui/material";
import HomeLayout from "~/layouts/home/HomeLayout";
import { GradientTypography } from "~/components/common/IPTypo";
import { styled } from "@mui/material/styles";
import Number from "~/components/animation/Number";
import { useState } from "react";
import Image from "~/components/common/Image";
import {
  bioC,
  busanC,
  inhaC,
  kaistC,
  kocC,
  smctC,
  swC,
  unescoC,
  vankC,
  scienceC,
  worldvisionC,
  sparklabC,
  edutechC,
  msBadge,
  msStartups,
  gcpStartup,
} from "~/assets";

interface IState {
  images: any[];
}

const Page = () => {
  const [state, setState] = useState<IState>({
    images: [
      msStartups,
      gcpStartup,
      worldvisionC,
      kaistC,
      unescoC,
      sparklabC,
      edutechC,
      busanC,
      smctC,
      kocC,
      bioC,
      inhaC,
      vankC,
      scienceC,
      swC,
    ],
  });

  return (
    <HomeLayout>
      <StyledTitleWrap>
        <Typography component="h3" variant="h3" color="secondary">
          지식 콘텐츠 개발 협력을 위해
          <br />
          <GradientTypography title="국내외 유수의 기관" />
          들과 함께해요.
        </Typography>
      </StyledTitleWrap>
      <StyledImagesWrap container>
        {state.images &&
          state.images.map((image, index) => {
            return (
              <StyledImage item xs={6} md={3} lg={3} xl={3}>
                <Box className={index === 11 ? "inha-univ" : ""}>
                  <Image src={image} alt="파트너 로고" />
                </Box>
              </StyledImage>
            );
          })}
      </StyledImagesWrap>
      <StyledMSWrap>
        <Image src={msBadge} alt="MS 로고" />
      </StyledMSWrap>
      <StyledMainWrap>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <StyledAnimation>
              <StyledNumberWrap>
                <StyledNumber>
                  <Number data={120} unit={"명"} />
                </StyledNumber>
              </StyledNumberWrap>
              <Typography component="h6" variant="h6">
                함께하는 전문가
              </Typography>
            </StyledAnimation>
          </Grid>
          <Grid item xs={12} sm={4}>
            <StyledAnimation>
              <StyledNumberWrap>
                <StyledNumber>
                  <Number data={80} unit={"개"} />
                </StyledNumber>
              </StyledNumberWrap>
              <Typography component="h6" variant="h6">
                운영한 클래스
              </Typography>
            </StyledAnimation>
          </Grid>
          <Grid item xs={12} sm={4}>
            <StyledAnimation>
              <StyledNumberWrap>
                <StyledNumber>
                  <Number data={3000} unit={"명"} />
                </StyledNumber>
              </StyledNumberWrap>
              <Typography component="h6" variant="h6">
                교육받은 청소년
              </Typography>
            </StyledAnimation>
          </Grid>
        </Grid>
      </StyledMainWrap>
    </HomeLayout>
  );
};

const StyledTitleWrap = styled(Box)(({ theme }) => ({
  textAlign: "center",
  "& h3": {
    fontWeight: 700,
    [theme.breakpoints.down("md")]: { fontSize: theme.typography.h5.fontSize },
  },
  [theme.breakpoints.down("md")]: { marginBottom: 20 },
}));

const StyledImagesWrap = styled(Grid)(({ theme }) => ({
  maxWidth: 780,
  margin: "0 auto",
  paddingTop: 50,
  paddingBottom: 50,
  textAlign: "center",
  "& img": { width: "100%", height: "auto" },
  [theme.breakpoints.down("md")]: {
    paddingTop: 20,
    paddingBottom: 20,
    "& img": { width: "90%" }, // mobile partners images
  },
}));

const StyledImage = styled(Grid)(({ theme }) => ({
  textAlign: "center",
  "& .inha-univ img": {
    width: "85%",
  },
  "& > div": {
    position: "relative",
    marginTop: theme.spacing(1.25),
    padding: theme.spacing(0.7),
    borderRadius: 15,
    background: "#f7f9fa",
    width: 180,
    height: 80,
    "& img": {
      position: "absolute",
      width: "95%",
      height: "auto",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0.5),
      margin: theme.spacing(0.5),
      width: "95%",
      "& img": { width: "85%" },
    },
  },
}));

const StyledMainWrap = styled(Box)(({ theme }) => ({
  background: "",
  maxWidth: "70%",
  margin: "0 auto",
}));

const StyledMSWrap = styled(Box)(({ theme }) => ({
  marginBottom: 30,
  maxWidth: 450,
  marginLeft: "auto",
  marginRight: "auto",
  position: "relative",
  textAlign: "center",
  "& img": {
    width: "100%",
    height: "auto",
  },
  [theme.breakpoints.down("md")]: {
    maxWidth: "85%",
    marginBottom: 15,
  },
}));

const StyledAnimation = styled(Box)(({ theme }) => ({
  textAlign: "center",
  background: "#f7f9fa",
  padding: 15,
  margin: 15,
  borderRadius: 15,
  [theme.breakpoints.down("md")]: { padding: 5, margin: 5 },
}));

const StyledNumberWrap = styled(Box)(({ theme }) => ({
  textAlign: "center",
  position: "relative",
  height: 50,
  [theme.breakpoints.down("md")]: { height: 40 },
}));

const StyledNumber = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: "50%",
  transform: "translateX(-50%)",
}));

export default Page;
