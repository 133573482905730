import "plyr-react/plyr.css";
import Plyr from "plyr-react";
import { Grid, Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import HomeLayout from "~/layouts/home/HomeLayout";
import { GradientTypography } from "~/components/common/IPTypo";

// 임팩터스 교육 유튜브 영상
const Video = () => {
  return (
    <HomeLayout>
      <StyledTitleWrap>
        <Typography component="h3" variant="h3" color="secondary">
          나의 임팩트가 <GradientTypography title="우리의 임팩트" />가<br />{" "}
          되는 방법을 연구해요.
        </Typography>
      </StyledTitleWrap>
      <StyledRoot>
        <Grid container>
          <Grid item xs={12}>
            <StyledVideo>
              <Plyr
                options={{
                  controls: [
                    "play",
                    "progress",
                    "mute",
                    "volume",
                    "fullscreen",
                  ],
                }}
                source={{
                  type: "video",
                  // @ts-ignore
                  sources: [
                    {
                      src: "https://static.jazzy.kr/%E1%84%80%E1%85%B3%E1%86%B7%E1%84%92%E1%85%A9%E1%84%80%E1%85%A9_%E1%84%8B%E1%85%B5%E1%86%B7%E1%84%91%E1%85%A2%E1%86%A8%E1%84%90%E1%85%B3%E1%84%8B%E1%85%A1%E1%84%8F%E1%85%A1%E1%84%83%E1%85%A6%E1%84%86%E1%85%B5_comp.mp4",
                      provider: "html5",
                    },
                  ],
                }}
              />
            </StyledVideo>
          </Grid>
        </Grid>
      </StyledRoot>
    </HomeLayout>
  );
};

const StyledTitleWrap = styled(Box)(({ theme }) => ({
  "& h3": {
    textAlign: "center",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: { fontSize: theme.typography.h5.fontSize },
  },
}));

const StyledRoot = styled(Grid)(({ theme }) => ({
  paddingTop: 50,
  [theme.breakpoints.down("sm")]: {
    paddingTop: 20,
  },
}));

const StyledVideo = styled(Grid)(({ theme }) => ({
  maxWidth: "50%",
  margin: "0 auto",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "90%",
  },
}));

export default Video;
