import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

interface TypoIProps {
  title: string;
  variant?: any;
}

const GradientTypography = ({ title, variant }: TypoIProps) => {
  return (
    <StyledGradient
      variant={variant || "h3"}
      color="secondary"
      fontWeight={600}
    >
      {title}
    </StyledGradient>
  );
};

const StyledGradient = styled(Typography)(({ theme }) => ({
  display: "inline-block",
  // "-webkit-background-clip": "text",
  // "-webkit-text-fill-color": "transparent",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  backgroundImage: `linear-gradient(to right, #fdb813, ${theme.palette.error.dark})`,
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("xs")]: {
    fontSize: theme.typography.h5.fontSize,
  },
}));

const StyledUnderline = styled(Typography)(({ theme }) => ({
  zIndex: -1,
  position: "absolute",
  display: "inline-block",
  bottom: "-0.5rem",
  content: "''",
  height: 15,
  width: "24rem",
  left: "50%",
  background: theme.palette.error.main,
  "-webkit-transform": "translate(-50%,-50%)",
  // "-moz-transform": "translate(-50%,-50%)",
  // "-ms-transform": "translate(-50%,-50%)",
  // "-o-transform": "translate(-50%,-50%)",
  transform: "translate(-50%,-50%)",
}));

export { GradientTypography };
