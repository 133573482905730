import { useState } from "react";
import { Grid, Typography, Box, Button } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { GradientTypography } from "~/components/common/IPTypo";
import HomeLayout from "~/layouts/home/HomeLayout";
import { renderHTML } from "~/libs/utils";
import { useBaseStore, useUserStore } from "~/libs/store";
import { useNavigate } from "react-router-dom";
import { FirebaseLogger } from "~/libs/ga";

// 임스 솔루션 활용 교육 간단 소개

// - 실시간 내용 자동저장과 그룹 활동
// - 학습 자료를 한 곳에 모아보기
// - 학생 개개인 활동 익명성 보장
// - 종이 학습지는 이제 그만, 보드&카드/질문&답변형
// - AI를 활용해 탐구 정보를 요약하고 평가하세요.
// - 이런 것도 된답니다. 투표&설문&발표

const Solution = () => {
  const isMobile = useBaseStore((state) => state.isMobile);
  const [state, setState] = useState({
    tab: 0,
    data: [
      {
        title: "실시간 그룹 활동",
        src: "https://static.jazzy.kr/solution2%2Frealtime.png",
        summary:
          "저장 버튼 없이 수정한 내용이 자동으로 저장되는 편리한 에디터예요.<br/>활동 중 실시간으로 피드백해줄 수 있고, 그룹 내에서 간편하게 소통할 수 있어요.",
        moSummary:
          "저장 버튼 없이 수정한 내용이 자동으로 저장되는 편리한 에디터예요. 활동 중 실시간으로 피드백해줄 수 있고, 그룹 내에서 간편하게 소통할 수 있어요.",
      },
      {
        title: "학습자료 모아보기",
        src: "https://static.jazzy.kr/solution2%2Farchiving.png",
        summary:
          "영상, 이미지, 링크, 파일 등 수업에 필요한 모든 유형의 자료와<br/>학생들의 수업 결과물을 한 곳에서 관리할 수 있어요.",
        moSummary:
          "영상, 이미지, 링크, 파일 등 수업에 필요한 모든 유형의 자료와 학생들의 수업 결과물을 한 곳에서 관리할 수 있어요.",
      },
      {
        title: "온라인 활동지",
        src: "https://static.jazzy.kr/solution2%2Fqna.png",
        summary:
          "학생들에게 질문을 하고, 답변을 받아야 할 때는 '질문 카드' 기능을 활용해 보세요.<br/>활동지를 인쇄하거나, 문서 파일을 저장하는 불편함을 줄여줘요.",
        moSummary:
          "학생들에게 질문을 하고, 답변을 받아야 할 때는 '질문 카드' 기능을 활용해 보세요. 활동지를 인쇄하거나, 문서 파일을 저장하는 불편함을 줄여줘요.",
      },
      {
        title: "개인 맞춤형 평가",
        src: "https://static.jazzy.kr/solution2%2Fevaluate.png",
        summary:
          "AI 평가 기능을 활용하여 학생들의 수업 참여 결과를 개별적으로 관리하고 시각화 자료를 생성할 수 있어요.<br/>수업에서 활용한 교육과정 성취기준을 직접 입력하여 정확한 맞춤형 평가가 가능해요.",
        moSummary:
          "AI 평가 기능을 활용하여 학생들의 수업 참여 결과를 개별적으로 관리하고 시각화 자료를 생성할 수 있어요. 수업에서 활용한 교육과정 성취기준을 직접 입력하여 정확한 맞춤형 평가가 가능해요.",
      },
      {
        title: "수업 지원 도구",
        src: "https://static.jazzy.kr/solution2%2Fdigital.png",
        summary:
          "학생들의 참여도를 높일 수 있는 다양한 수업 도구를 지원해요.<br/>발표, 투표, 설문 등 다양한 형태의 데이터도 효율적으로 관리할 수 있어요.",
        moSummary:
          "학생들의 참여도를 높일 수 있는 다양한 수업 도구를 지원해요. 발표, 투표, 설문 등 다양한 형태의 데이터도 효율적으로 관리할 수 있어요.",
      },
    ],
  });
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();

  const onHandleTab = (tab: number) => {
    FirebaseLogger("click", { event: `홈-솔루션-${state.data?.[tab]?.title}` });
    setState((prev) => ({ ...prev, tab }));
  };

  const onHandleMove = () => {
    FirebaseLogger("click", { event: `홈-솔루션-바로가기` });
    navigate(user ? "/space" : "/login");
  };

  return (
    <HomeLayout bgColor={"grey"}>
      <StyledTitleWrap>
        <Typography component="h3" variant="h3" color="secondary">
          스마트한 교육 현장을 위한
          <br />
          <GradientTypography title="디지털 학습 공간" /> 임팩트 스페이스
        </Typography>
      </StyledTitleWrap>
      <StyledRoot>
        <StyledTabs>
          {state.data.map((item, index) => {
            return (
              <StyledTab
                className={`${state.tab === index ? "active" : ""}`}
                onClick={() => {
                  onHandleTab(index);
                }}
              >
                <Typography
                  component="p"
                  variant="subtitle1"
                  color="secondary.main"
                >
                  {item.title}
                </Typography>
              </StyledTab>
            );
          })}
        </StyledTabs>
        <StyledContent>
          <Box sx={{ maxWidth: "90%", margin: "0 auto" }}>
            <img
              src={state.data?.[state.tab]?.src}
              alt={state.data?.[state.tab]?.summary}
            />
            <Typography
              component="h6"
              variant="h6"
              color="secondary.dark"
              gutterBottom
              sx={{ lineHeight: "125%", mt: (theme) => theme.spacing(2) }}
            >
              {renderHTML(
                isMobile
                  ? state.data?.[state.tab]?.moSummary
                  : state.data?.[state.tab]?.summary
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              textAlign: "center",
              margin: "0 auto",
              maxWidth: `200px !important`,
            }}
          >
            <StyledActionBtn
              fullWidth
              variant="contained"
              color="info"
              onClick={onHandleMove}
              endIcon={<ArrowForward />}
            >
              <Typography component="h6" variant="h6" fontWeight={500}>
                무료로 시작하기
              </Typography>
            </StyledActionBtn>
          </Box>
        </StyledContent>
      </StyledRoot>
    </HomeLayout>
  );
};

const StyledTitleWrap = styled(Box)(({ theme }) => ({
  "& h3": {
    textAlign: "center",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: { fontSize: theme.typography.h5.fontSize },
  },
}));

const StyledRoot = styled(Grid)(({ theme }) => ({
  textAlign: "center",
}));

const StyledTabs = styled(Grid)(({ theme }) => ({
  maxWidth: 1200,
  margin: "0 auto",
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {},
}));

const StyledTab = styled(Grid)(({ theme }) => ({
  display: "inline-block",
  margin: theme.spacing(1),
  borderRadius: 2.5,
  border: `1px solid ${theme.palette.secondary.light}`,
  padding: 8,
  cursor: "pointer",
  "& > p": { fontWeight: 500 },
  "&.active": {
    background: theme.palette.info.dark,
    "& > p": { color: theme.palette.primary.main },
  },
  [theme.breakpoints.down("sm")]: {
    margin: theme.spacing(0.5),
    padding: 3,
    "& > p": { fontSize: theme.typography.subtitle2.fontSize },
  },
}));

const StyledContent = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  "& img": {
    maxWidth: "100%",
    height: 500,
  },
  [theme.breakpoints.down("xl")]: {
    "& img": {
      maxWidth: "100%",
      height: 415,
    },
  },
  [theme.breakpoints.down("lg")]: {
    "& > div": {
      maxWidth: "90%",
    },
    "& img": {
      maxWidth: "100%",
      height: 300,
    },
  },
  [theme.breakpoints.down("sm")]: {
    "& > div": {
      maxWidth: "95%",
    },
    "& h6": {
      fontSize: theme.typography.subtitle2.fontSize,
    },
    "& img": {
      maxWidth: "100%",
      height: 180,
    },
  },
}));

const StyledActionBtn = styled(Button)(({ theme }) => ({
  paddingTop: theme.spacing(1.25),
  paddingBottom: theme.spacing(1.25),
  [theme.breakpoints.down("sm")]: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    "& > h6": {
      fontSize: theme.typography.subtitle1.fontSize,
    },
  },
}));

export default Solution;
