import { useSpaceStore } from "~/libs/store";
import { getEmailPrefixID } from "~/libs/utils";
import { TypeOfSpace, TypeOfUser } from "~/types";

// 기본 유저 타입
export const defaultUserType = {
  profileImage: "", // 프로필 이미지
  userType: "personal" as TypeOfUser.UserType, // 사용자 타입
  type: 0 as TypeOfUser.UserPermissionType, // 권한 타입
  tier: "basic" as TypeOfUser.UserTier,
  employer: "", // 소속
  status: 0 as 0 | 1, // 회원 상태 (0: 기본 or 1: 정지)
  interest: [], // 관심사
  point: 0, // 포인트 추후 사용
  pushToken: "", // 웹 푸시토큰
  isTutorial: false, // 튜토리얼 완료 여부
  classes: [], // 현재 클래스 구입 리스트
  subscribe: "", // 현재 구독권 결제 정보
  mentoring: [], // 현재 멘토링 신청(결제) 리스트
  setting: {
    mentoring: false, // 멘토링 알림
    classes: false, // 클래스/구독권 만료 알림
    review: false, // 클래스/게시판 리뷰 알림
    board: false, // 게시판 알림
    order: false, // 배송 알림
    alert: false, // 시스템 종합 알림
    event: false, // 이벤트 알림
  }, // 시스템 알림 설정 추후 사용성을 위해 미리 저장
};

// 관심사 목록
export const interestList = [
  "언어ㆍ문학",
  "인문학",
  "역사",
  "경영ㆍ경제",
  "법학",
  "사회과학",
  "교육",
  "농림ㆍ수산",
  "화학",
  "환경",
  "생명과학",
  "생활과학",
  "간호",
  "보건",
  "약학",
  "의료예과",
  "수리ㆍ과학",
  "천문ㆍ지구",
  "전기ㆍ전자",
  "컴퓨터",
  "건설",
  "산업ㆍ안전",
  "기계",
  "교통・수송",
  "무용ㆍ체육",
  "연극ㆍ영화",
  "응용예술",
  "미술",
  "음악",
];

// Typesense 저장용 스키마
export const userSchemaFieldList = [
  { name: "uid", type: "string" },
  { name: "displayName", type: "string" },
  { name: "name", type: "string" },
  { name: "tier", type: "string" },
  { name: "profileImage", type: "string" },
  { name: "profile", type: "string" },
  { name: "gender", type: "string" },
  { name: "email", type: "string" },
  { name: "provider", type: "string" },
  { name: "phoneNumber", type: "string" },
  { name: "personalId", type: "string" },
  { name: "userType", type: "string" },
  { name: "employer", type: "string" },
  { name: "birthday", type: "string" },
  { name: "interest", type: "string[]" },
  { name: "memo", type: "string" },
  { name: "type", type: "int32" },
  { name: "status", type: "int32" },
  { name: "statusMsg", type: "string" },
  { name: "usage", type: "object" },
  { name: "notification", type: "object" },
  { name: "point", type: "int32" },
  { name: "classes", type: "object[]" },
  { name: "mentoring", type: "object[]" },
  { name: "deviceIds", type: "object[]" },
  { name: "subscribe", type: "string" },
  { name: "coupon", type: "object[]" },
  { name: "create", type: "int32" },
  { name: "update", type: "int32" },
] as { name: string; type: string }[];

// Typesense 저장용 스키마 추출
export const userSchemaExtract = (user: TypeOfUser.User) => {
  const userData = JSON.parse(JSON.stringify(user));
  const data = {};
  userSchemaFieldList.forEach((field) => {
    if (field.type === "string") {
      data[field.name] = userData?.[field.name] || "";
    }
    if (field.type === "object") {
      data[field.name] = userData?.[field.name] || {};
    }
    if (field.type === "string[]") {
      data[field.name] = userData?.[field.name] || [];
    }
    if (field.type === "object[]") {
      data[field.name] = userData?.[field.name] || [];
    }
    if (field.type === "int32") {
      data[field.name] = userData?.[field.name] || 0;
    }
    if (field.type === "bool") {
      data[field.name] = userData?.[field.name] || false;
    }
  });
  return data;
};

// user 객체로 스페이스 프로필 이름 가져오기
export const getUserNameWithCondition = (
  user: TypeOfUser.User | TypeOfSpace.PermissionUser
): string => {
  const displayName =
    user?.name ||
    user?.displayName ||
    getEmailPrefixID(user?.email || "") ||
    "";
  return displayName;
};

// uid로 스페이스 프로필 이름 가져오기
export const getUserNameByUID = (uid: string): string => {
  const permissionUser =
    useSpaceStore.getState()?.selectedSpace?.permissionUser;
  const user = permissionUser?.find((user) => user?.uid === uid);
  if (!user) return "Unknown";

  const displayName =
    user?.name ||
    user?.displayName ||
    getEmailPrefixID(user?.email || "") ||
    "";
  return displayName;
};

// uid로 스페이스 프로필 이미지 가져오기
export const getUserProfileImageByUID = (uid: string): string => {
  const permissionUser =
    useSpaceStore.getState()?.selectedSpace?.permissionUser;
  const user = permissionUser?.find((user) => user?.uid === uid);
  if (!user) return "Unknown";

  return user?.thumbnail || "";
};

// uid로 스페이스 프로필 학번 가져오기
export const getUserPersonalIdByUID = (
  uid: string,
  isSlash: boolean = true
): string => {
  const permissionUser =
    useSpaceStore.getState()?.selectedSpace?.permissionUser;
  const user = permissionUser?.find((user) => user?.uid === uid);
  if (!user) return "Unknown";

  const personalId = user?.personalId ? user?.personalId : isSlash ? "-" : "";
  return personalId;
};
