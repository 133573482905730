import AppRoutes from "./routes";
import { useLocation } from "react-router-dom";
import ErrorBoundary from "~/components/boundary/ErrorBoundary";
import Core from "~/components/common/Core";
import ScrollToTop from "~/components/common/ScrollToTop";

function App() {
  const location = useLocation();

  return (
    <ErrorBoundary>
      <ScrollToTop />
      <Core />
      <AppRoutes location={location} />
    </ErrorBoundary>
  );
}

export default App;
