import mixpanel from "mixpanel-browser";

// REF: https://medium.com/@andrewoons/setting-up-mixpanel-in-react-3e4c5b8c2a36
const env_check = import.meta.env.PROD;

mixpanel.init(import.meta.env.VITE_MIXPANEL_KEY, {
  debug: !env_check,
});

const panel = {
  identify: (id: string) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id: string) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name: string, props: any) => {
    if (env_check) mixpanel.track(name, props);
  },
  set_config: () => {
    if (env_check) mixpanel.set_config({ persistence: "localStorage" });
  },
  people: {
    set: (props: any) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export default panel;
